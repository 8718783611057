import {
  memo,
  useMemo,
  useEffect,
  useState
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Link.module.scss';

const LinkComponent = ({
  href,
  locale,
  skipLocaleHandling,
  simpleStyle,
  className,
  activeClassName,
  children,
  ...props
}) => {
  const {
    asPath,
    pathname,
    query,
    isReady
  } = useRouter();
  const [active, setActive] = useState(false);

  const resultHref = useMemo(() => {
    const slug = locale || query.locale;
    const to = href || asPath;

    if (skipLocaleHandling || !slug || to.includes('http')) {
      return to;
    }

    if (to) {
      return `/${slug}${to}`;
    }

    return pathname.replace('[locale]', slug);
  }, [
    href,
    locale,
    asPath,
    pathname,
    query.locale,
    skipLocaleHandling
  ]);

  useEffect(() => {
    if (isReady) {
      const linkPathname = new URL(resultHref, window.location.href).pathname;
      const activePathname = new URL(asPath, window.location.href).pathname;
      setActive(linkPathname === activePathname);
    }
  }, [asPath, isReady, resultHref]);

  return (
    <Link href={resultHref}>
      <a
        {...props}
        className={cn({
          [styles.simple]: simpleStyle,
          [activeClassName]: active && activeClassName
        }, className)}
      >
        {children}
      </a>
    </Link>
  );
};

LinkComponent.propTypes = {
  href: PropTypes.string,
  locale: PropTypes.string,
  skipLocaleHandling: PropTypes.bool,
  simpleStyle: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node
};

LinkComponent.defaultProps = {
  href: null,
  locale: null,
  skipLocaleHandling: false,
  simpleStyle: false,
  className: null,
  activeClassName: null,
  children: null
};

export default memo(LinkComponent);
