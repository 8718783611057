import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../../UI/Container';
import LangSwitch from '../LangSwitch';
import Copyright from '../Copyright';
import Link from '../../Link';

import styles from './SmallFooter.module.scss';

const SmallFooter = () => {
  const { t } = useTranslation('layout');

  return (
    <Container>
      <div className={styles.root}>
        <Copyright />

        <div className={styles.right}>
          <Link
            href="/contacts/"
            simpleStyle
            className={styles.link}
          >
            {t('Nav.support')}
          </Link>

          <div className={styles.langSwitch}>
            <LangSwitch />
          </div>
        </div>
      </div>
    </Container>
  );
};

SmallFooter.propTypes = {
};

SmallFooter.defaultProps = {
  small: false
};

export default memo(SmallFooter);
