import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setModal } from '../redux/app/actions';

const useModal = (name, data) => {
  const dispatch = useDispatch();
  const open = useCallback(() => {
    dispatch(setModal(name, data));
  }, [data, dispatch, name]);
  const close = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch]);

  return {
    open,
    close
  };
};

export default useModal;
