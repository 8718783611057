import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { authorizedSelector } from '../../../redux/auth/selectors';

import HowCancelSubscription from './HowCancelSubscription';

const mapStateToProps = createStructuredSelector({
  authorized: authorizedSelector
});

export default connect(mapStateToProps)(HowCancelSubscription);
