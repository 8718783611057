import { useTranslation } from 'react-i18next';

import { getSavedLocale } from '../utils';

const useLocale = () => {
  const { i18n } = useTranslation();

  return i18n.language || getSavedLocale();
};

export default useLocale;
