import { createSelector } from 'reselect';
import { defaultFetchingSelector, defaultDataSelector } from '../utils';

const slice = ({ profile }) => profile;

export const fetchingSelector = defaultFetchingSelector(slice);
export const dataSelector = defaultDataSelector(slice, null);
export const readySelector = createSelector(dataSelector, (data) => !!data);
export const idSelector = createSelector(dataSelector, (data) => data?.id || null);
export const emailSelector = createSelector(dataSelector, (data) => data?.email?.uid || null);
export const isEmailVerifiedSelector = createSelector(dataSelector, (data) => !!data?.email?.verifiedAt);
export const nameSelector = createSelector(emailSelector, (email) => email?.replace(/@.*$/, '') || '');
export const avatarSelector = createSelector(dataSelector, (data) => data?.avatar || '');
export const subscriptionSelector = createSelector(dataSelector, (data) => data?.subscription || null);
export const locationSelector = createSelector(dataSelector, (data) => data?.location || null);
export const hasPasswordSelector = createSelector(dataSelector, (data) => data?.hasPassword || false);
export const countryNameSelector = createSelector(dataSelector, (data) => data?.location?.country?.name || '');
export const countryCodeSelector = createSelector(dataSelector, (data) => data?.location?.country?.code || '');

export const canChangeCountrySelector = createSelector(
  locationSelector,
  (location) => location?.canChangeCountry || false
);
export const subscriptionUidSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.uid || null
);
export const subscriptionPriceUidSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.price?.uid || null
);
export const subscriptionNameSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.plan?.name || null
);
export const subscriptionStatusSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.status || null
);
export const subscriptionExpireAtSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.expireAt || null
);
export const subscriptionActiveSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.isActive || false
);
export const subscriptionAutoRenewalSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.autoRenewal || false
);
export const hasSubscriptionSelector = createSelector(
  subscriptionActiveSelector,
  subscriptionAutoRenewalSelector,
  (active, autoRenewal) => active || autoRenewal
);
