import { HYDRATE } from 'next-redux-wrapper';
import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import {
  GET_GAMES_LIST,
  SET_SEARCH,
  SET_SORTING
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  search: '',
  sorting: 'byName'
};

const gamesList = createReducer(initialState, {
  [GET_GAMES_LIST]: defaultFetchReducer,

  [SET_SEARCH]: (state, { search }) => ({
    ...state,
    search
  }),

  [SET_SORTING]: (state, { sorting }) => ({
    ...state,
    sorting
  }),

  [HYDRATE]: (state, action) => ({
    ...state,
    ...action.payload.gamesList
  })
});

export default gamesList;
