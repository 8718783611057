import { createSelector } from 'reselect';
import { defaultFetchingSelector, defaultDataSelector } from '../utils';

import { dataSelector as couponSelector } from '../coupon/selectors';

const LIFETIME_DISCOUNT = process.env.NEXT_PUBLIC_LIFETIME_DISCOUNT;
const LIFETIME_DISCOUNT_DATE = process.env.NEXT_PUBLIC_LIFETIME_DISCOUNT_DATE;

const slice = ({ plansList }) => plansList;

const getPlanDiscount = (name) => {
  const value = parseFloat(LIFETIME_DISCOUNT, 10);

  if (name !== 'lifetime' || Number.isNaN(value)) {
    return null;
  }

  return {
    value,
    valuePercentage: `${Math.round(value * 100)}%`,
    date: LIFETIME_DISCOUNT_DATE || ''
  };
};

const getPriceCoupon = (price, withTrial, discount, coupon) => {
  if (!coupon || withTrial || discount) {
    return null;
  }

  const { hasPriceRestrictions, prices } = coupon;
  const value = parseFloat(coupon.discount, 10);
  const result = {
    value,
    valuePercentage: `${Math.round(value * 100)}%`,
    hasPriceRestrictions
  };

  if (!hasPriceRestrictions || !Array.isArray(prices)) {
    return result;
  }

  const couponPrice = prices.find((item) => item.uid === price.uid);

  if (couponPrice) {
    return {
      ...result,
      finalAmount: couponPrice.finalAmount
    };
  }

  return null;
};

const getPriceAmount = (amount, coupon) => {
  if (coupon) {
    if (!coupon.hasPriceRestrictions) {
      return Math.round((1 - coupon.value) * amount * 100) / 100;
    }

    if (coupon.finalAmount) {
      return coupon.finalAmount;
    }
  }

  return amount || 0;
};

const getOldPriceAmount = (amount, discount, coupon) => {
  if (discount) {
    return Math.round(amount / (1 - discount.value));
  }

  if (coupon) {
    return amount;
  }

  return null;
};

export const fetchingSelector = defaultFetchingSelector(slice);
export const dataSelector = defaultDataSelector(slice, null);
export const errorSelector = createSelector(slice, ({ error }) => error || false);
export const listSelector = createSelector(
  dataSelector,
  couponSelector,
  (data, couponData) => {
    if (!data || !data.length) {
      return [];
    }
    return ['yearly-trial', 'monthly', 'every-six-months', 'yearly', 'lifetime']
      .filter((name) => {
        const plan = data.find((item) => item.name === name);
        return !!plan && Array.isArray(plan.prices) && plan.prices.length;
      })
      .map((name) => {
        const plan = data.find((item) => item.name === name);
        const price = plan.prices?.find(({ type }) => type === 'recurring') || plan.prices[0];
        const withTrial = !!plan.trialPeriodDays;
        const discount = getPlanDiscount(name);
        const coupon = getPriceCoupon(price, withTrial, discount, couponData);
        const priceAmount = getPriceAmount(price.amount, coupon);
        const oldPriceAmount = getOldPriceAmount(price.amount, discount, coupon);

        return {
          name,
          withTrial,
          uid: price.uid,
          price: priceAmount,
          oldPrice: oldPriceAmount,
          coupon,
          discount,
          currency: price.currency?.code || null,
          trialPeriodDays: plan.trialPeriodDays,
          intervalDays: plan.intervalDays,
          providers: price.providers
        };
      });
  }
);
export const pricesUidsSelector = createSelector(
  listSelector,
  (list) => list.map(({ uid }) => uid)
);
