import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';

import styles from './SidebarToggle.module.scss';

const SidebarToggle = ({
  cross,
  onClick
}) => (
  <button
    type="button"
    className={cn(styles.root, { [styles.cross]: cross })}
    onClick={onClick}
  >
    <span />
    <span />
    <span />
  </button>
);

SidebarToggle.propTypes = {
  cross: PropTypes.bool,
  onClick: PropTypes.func
};

SidebarToggle.defaultProps = {
  cross: false,
  onClick: noop
};

export default memo(SidebarToggle);
