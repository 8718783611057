import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import usePrevious from './usePrevious';
import { sendCollectedData } from '../redux/analytics/actions';

const useCollectAnalytics = () => {
  const dispatch = useDispatch();
  const { query, isReady } = useRouter();
  const prevIsReady = usePrevious(isReady);

  useEffect(() => {
    if (!isReady || prevIsReady) {
      return;
    }

    dispatch(sendCollectedData(query));
  }, [
    dispatch,
    isReady,
    prevIsReady,
    query
  ]);
};

export default useCollectAnalytics;
