import { memo } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation, Trans } from 'react-i18next';
import Image from '../../UI/Image';
import styles from './CookieConsentBanner.module.scss';
import useLocaleBasedEnv from '../../../hooks/useLocaleBasedEnv';
import Link from '../../Link';

import cookieConsentBannerPicture from './cookie-consent-banner-picture.svg';

const CookieConsentBanner = () => {
  const { t } = useTranslation('common', { keyPrefix: 'CookieConsent' });
  const { POLICY_URL } = useLocaleBasedEnv();

  return (
    <CookieConsent
      containerClasses={styles.container}
      buttonClasses={styles.button}
      buttonText=""
      contentClasses={styles.message}
      acceptOnScroll
    >
      <div className={styles.imageBox}>
        <div className={styles.image}>
          <Image
            src={cookieConsentBannerPicture}
            alt="Cookie Consent Banner"
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
      <span>
        <Trans
          t={t}
          i18nKey="message"
        >
          <Link
            href={POLICY_URL}
            target="_blank"
          />
        </Trans>
      </span>
    </CookieConsent>
  );
};

export default memo(CookieConsentBanner);
