import {
  createReducer,
  createRequestReducer
} from '../utils';
import { GET_PLANS_LIST, CLEAR_PLANS_LIST } from './constants';

const initialState = {
  data: null,
  fetching: false,
  error: false
};

const plansList = createReducer(initialState, {
  [GET_PLANS_LIST]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true,
      error: false
    }),

    SUCCESS: () => {
      const data = action.request?.response?.data || [];
      const plans = data[0]?.plans || [];

      return {
        ...state,
        fetching: false,
        data: plans,
        error: false
      };
    },

    FAIL: () => ({
      ...state,
      fetching: false,
      error: true
    })
  }),

  [CLEAR_PLANS_LIST]: () => ({
    ...initialState
  })
});

export default plansList;
