import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { noop } from '../../../utils';
import Link from '../../Link';
import Heading from '../../UI/Heading';
import ButtonLink from '../../UI/ButtonLink';

import styles from './HowCancelSubscription.module.scss';

const email = process.env.NEXT_PUBLIC_SUPPORT_EMAIL;

const HowCancelSubscription = ({ authorized, onClose }) => {
  const { t } = useTranslation('common', { keyPrefix: 'Modals.HowCancelSubscription' });
  const href = useMemo(() => (
    authorized ? '/profile/subscription/' : '/sign-in/'
  ), [authorized]);

  return (
    <>
      <Heading
        level="4"
        className={styles.heading}
      >
        <Trans
          t={t}
          i18nKey="title"
        />
      </Heading>

      <div className={styles.text}>
        <Trans
          t={t}
          i18nKey="text"
          values={{ email }}
        >
          <ol>
            <li>
              Sign in to <Link href={href}>account</Link> using your email address.
              If you do not remember your e-mail, then write to us - <a href={`mailto:${email}`}>{{ email }}</a>
            </li>
            <li>
              Select &quot;Subscription&quot;
            </li>
            <li>
              Click &quot;Cancel&quot;
            </li>
          </ol>
        </Trans>
      </div>

      <ButtonLink
        href={href}
        className={styles.button}
        onClick={onClose}
      >
        {t('buttonLinkText')}
      </ButtonLink>
    </>
  );
};

HowCancelSubscription.propTypes = {
  authorized: PropTypes.bool,
  onClose: PropTypes.func
};

HowCancelSubscription.defaultProps = {
  authorized: false,
  onClose: noop
};

export default memo(HowCancelSubscription);
