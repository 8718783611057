import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../UI/Icon';
import Hidden from '../../UI/Hidden';

import styles from './ScrollUpButton.module.scss';

const ScrollUpButton = () => {
  const { t } = useTranslation('layout', { keyPrefix: 'ScrollUpButton' });
  const onClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <button
      type="button"
      className={styles.root}
      onClick={onClick}
    >
      <Icon
        name="ScrollUpDown"
        size="22"
      />

      <Hidden
        xs
        sm
        tag="span"
        className={styles.text}
      >
        {t('buttonTitle')}
      </Hidden>
    </button>
  );
};

ScrollUpButton.propTypes = {
};

ScrollUpButton.defaultProps = {
};

export default memo(ScrollUpButton);
