import { memo } from 'react';
import Snowfall from 'react-snowfall';

import styles from './Snow.module.scss';

const Snow = () => (
  <div className={styles.root}>
    <Snowfall
      snowflakeCount={100}
      changeFrequency={75}
      speed={[0.5, 1.2]}
    />
  </div>
);

export default memo(Snow);
