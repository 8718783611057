import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as ArrowDown } from './icons/ArrowDown.svg';
import { ReactComponent as ArrowDownLeft } from './icons/ArrowDownLeft.svg';
import { ReactComponent as ArrowUp } from './icons/ArrowUp.svg';
import { ReactComponent as Blocked } from './icons/Blocked.svg';
import { ReactComponent as Card } from './icons/Card.svg';
import { ReactComponent as ChatBubble } from './icons/ChatBubble.svg';
import { ReactComponent as Check } from './icons/Check.svg';
import { ReactComponent as CheckBold } from './icons/CheckBold.svg';
import { ReactComponent as Chevron } from './icons/Chevron.svg';
import { ReactComponent as ChevronThin } from './icons/ChevronThin.svg';
import { ReactComponent as ChristmasTree } from './icons/ChristmasTree.svg';
import { ReactComponent as Cross } from './icons/Cross.svg';
import { ReactComponent as CrossInCircle } from './icons/CrossInCircle.svg';
import { ReactComponent as CrossThin } from './icons/CrossThin.svg';
import { ReactComponent as Crown } from './icons/Crown.svg';
import { ReactComponent as Download } from './icons/Download.svg';
import { ReactComponent as DownloadCircle } from './icons/DownloadCircle.svg';
import { ReactComponent as Ellipsis } from './icons/Ellipsis.svg';
import { ReactComponent as Envelope } from './icons/Envelope.svg';
import { ReactComponent as Exit } from './icons/Exit.svg';
import { ReactComponent as EyeOpen } from './icons/EyeOpen.svg';
import { ReactComponent as EyeClose } from './icons/EyeClose.svg';
import { ReactComponent as Gear } from './icons/Gear.svg';
import { ReactComponent as Globe } from './icons/Globe.svg';
import { ReactComponent as GlobeAlt } from './icons/GlobeAlt.svg';
import { ReactComponent as GlobeThin } from './icons/GlobeThin.svg';
import { ReactComponent as Grid } from './icons/Grid.svg';
import { ReactComponent as Handbag } from './icons/Handbag.svg';
import { ReactComponent as Info } from './icons/Info.svg';
import { ReactComponent as LeftRightTriangles } from './icons/LeftRightTriangles.svg';
import { ReactComponent as Lang } from './icons/Lang.svg';
import { ReactComponent as Magnifier } from './icons/Magnifier.svg';
import { ReactComponent as PaperPlane } from './icons/PaperPlane.svg';
import { ReactComponent as Pencil } from './icons/Pencil.svg';
import { ReactComponent as Plus } from './icons/Plus.svg';
import { ReactComponent as Question } from './icons/Question.svg';
import { ReactComponent as Radiation } from './icons/Radiation.svg';
import { ReactComponent as Reload } from './icons/Reload.svg';
import { ReactComponent as ScrollUpDown } from './icons/ScrollUpDown.svg';
import { ReactComponent as Signal } from './icons/Signal.svg';
import { ReactComponent as Telegram } from './icons/Telegram.svg';
import { ReactComponent as User } from './icons/User.svg';
import { ReactComponent as Vk } from './icons/Vk.svg';
import { ReactComponent as Warning } from './icons/Warning.svg';
import { ReactComponent as Windows } from './icons/Windows.svg';
import { ReactComponent as Youtube } from './icons/Youtube.svg';
import { ReactComponent as Discord } from './icons/Discord.svg';
import { ReactComponent as Fb } from './icons/Fb.svg';
import { ReactComponent as Instagram } from './icons/Instagram.svg';

import styles from './Icon.module.scss';

const ICON_NAME_MAP = {
  ArrowDown,
  ArrowDownLeft,
  ArrowUp,
  Blocked,
  Card,
  ChatBubble,
  Check,
  CheckBold,
  Chevron,
  ChevronThin,
  ChristmasTree,
  Cross,
  CrossInCircle,
  CrossThin,
  Crown,
  Download,
  DownloadCircle,
  Ellipsis,
  Envelope,
  Exit,
  EyeOpen,
  EyeClose,
  Gear,
  Globe,
  GlobeAlt,
  GlobeThin,
  Grid,
  Handbag,
  Info,
  LeftRightTriangles,
  Lang,
  Magnifier,
  PaperPlane,
  Pencil,
  Plus,
  Question,
  Radiation,
  Reload,
  ScrollUpDown,
  Signal,
  Telegram,
  User,
  Vk,
  Warning,
  Windows,
  Youtube,
  Discord,
  Fb,
  Instagram
};

export const ALL_ICONS_LIST = Object.keys(ICON_NAME_MAP);

const Icon = ({
  name,
  size,
  width,
  height,
  className,
  ...props
}) => {
  const Component = useMemo(() => ICON_NAME_MAP[name], [name]);
  const computedWidth = useMemo(() => size || width, [size, width]);
  const computedHeight = useMemo(() => size || height, [size, height]);

  if (!Component) {
    return null;
  }

  return (
    <Component
      width={computedWidth}
      height={computedHeight}
      className={cn(styles.root, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(ALL_ICONS_LIST),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

Icon.defaultProps = {
  name: null,
  size: null,
  width: null,
  height: null,
  className: null
};

export default memo(Icon);
