import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({
  className,
  children,
  ...props
}) => (
  <div
    className={cn(styles.root, className)}
    {...props}
  >
    {children}
  </div>
);

ErrorMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

ErrorMessage.defaultProps = {
  className: null,
  children: null
};

export default memo(ErrorMessage);
