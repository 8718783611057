import {
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import sourceForgeImage from './sourceForge.svg';
import slashdotImage from './slashdot.svg';
import topPerformerImage from './topPerformer.svg';
import trustPilotImage from './trustPilot.svg';
import styles from './BadgesList.module.scss';

const SF_ID = process.env.NEXT_PUBLIC_SOURCE_FORGE_ID;
const SF_BASE_URL = 'https://b.sf-syn.com/badge_img/';
const HREF_MAP = {
  sourceForge: 'https://sourceforge.net/software/product/Upgrader/',
  slashdot: 'https://slashdot.org/software/p/Upgrader/',
  topPerformer: 'https://sourceforge.net/software/product/Upgrader/',
  trustPilot: 'https://www.trustpilot.com/review/upgrader.gg'
};
const IMAGE_MAP = {
  sourceForge: `${SF_BASE_URL}${SF_ID}/light-default?&variant_id=sf&r=https://upgrader.gg/ru/`,
  slashdot: `${SF_BASE_URL}${SF_ID}/light-default?&variant_id=sd&r=https://upgrader.gg/ru/`,
  // eslint-disable-next-line max-len
  topPerformer: `${SF_BASE_URL}${SF_ID}/top-performer-summer-black?achievement=top-performer-seasonal-2022-summer&variant_id=sf&r=https://upgrader.gg/ru/`,
  trustPilot: trustPilotImage
};
const IMAGE_FALLBACK_MAP = {
  sourceForge: sourceForgeImage,
  slashdot: slashdotImage,
  topPerformer: topPerformerImage,
  trustPilot: trustPilotImage
};

const BadgeLink = memo(({ type }) => {
  const [error, setError] = useState(false);
  const href = useMemo(() => HREF_MAP[type], [type]);
  const image = useMemo(() => (error ? IMAGE_FALLBACK_MAP[type] : IMAGE_MAP[type]), [error, type]);

  const onImageError = useCallback(() => {
    setError(true);
  }, []);

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={cn(styles.link, styles[type])}
    >
      <img
        src={image}
        alt={type}
        className={styles.image}
        onError={onImageError}
      />
    </a>
  );
});

BadgeLink.propTypes = {
  type: PropTypes.oneOf([
    'sourceForge',
    'slashdot',
    'topPerformer',
    'trustPilot'
  ]).isRequired
};

const BadgesList = ({
  sourceForge,
  slashdot,
  topPerformer,
  trustPilot
}) => {
  if (!SF_ID) {
    return null;
  }

  return (
    <div className={styles.root}>
      {sourceForge && (
        <div className={styles.item}>
          <BadgeLink type="sourceForge" />
        </div>
      )}

      {slashdot && (
        <div className={styles.item}>
          <BadgeLink type="slashdot" />
        </div>
      )}

      {topPerformer && (
        <div className={styles.item}>
          <BadgeLink type="topPerformer" />
        </div>
      )}

      {trustPilot && (
        <div className={styles.item}>
          <BadgeLink type="trustPilot" />
        </div>
      )}
    </div>
  );
};

BadgesList.propTypes = {
  sourceForge: PropTypes.bool,
  slashdot: PropTypes.bool,
  topPerformer: PropTypes.bool,
  trustPilot: PropTypes.bool
};

BadgesList.defaultProps = {
  sourceForge: false,
  slashdot: false,
  topPerformer: false,
  trustPilot: false
};

export default memo(BadgesList);
