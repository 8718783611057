import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useLocale from '../../hooks/useLocale';
import useCountry from '../../hooks/useCountry';
import Icon from '../UI/Icon';

import styles from './SocialLinks.module.scss';

const VK_URL = process.env.NEXT_PUBLIC_VK_URL;
const YOUTUBE_URL = process.env.NEXT_PUBLIC_YOUTUBE_URL;
const TELEGRAM_URL = process.env.NEXT_PUBLIC_TELEGRAM_URL;
const DISCORD_URL = process.env.NEXT_PUBLIC_DISCORD_URL;
const FACEBOOK_URL = process.env.NEXT_PUBLIC_FACEBOOK_URL;
const INSTAGRAM_URL = process.env.NEXT_PUBLIC_INSTAGRAM_URL;

const ICON_SIZE_MAP = {
  md: 22,
  lg: 32
};

const SocialLinks = ({
  size,
  className
}) => {
  const country = useCountry();
  const locale = useLocale();

  const isRu = useMemo(() => country === 'ru' || locale === 'ru', [country, locale]);

  if (!VK_URL && !YOUTUBE_URL && !TELEGRAM_URL && !DISCORD_URL) {
    return null;
  }

  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.line, className)}>
        {!!VK_URL && isRu && (
          <a
            href={VK_URL}
            aria-label="VK"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.vk)}
          >
            <Icon
              name="Vk"
              size={ICON_SIZE_MAP[size]}
            />
          </a>

        )}

        {!!TELEGRAM_URL && isRu && (
          <a
            href={TELEGRAM_URL}
            aria-label="Telegram"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.telegram)}
          >
            <Icon
              name="Telegram"
              size={ICON_SIZE_MAP[size]}
            />
          </a>
        )}
      </div>

      <div className={cn(styles.line, className)}>
        {!!FACEBOOK_URL && country !== 'ru' && locale !== 'ru' && (
          <a
            href={FACEBOOK_URL}
            aria-label="FB"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.fb)}
          >
            <Icon
              name="Fb"
              size={ICON_SIZE_MAP[size]}
            />
          </a>

        )}

        {!!INSTAGRAM_URL && country !== 'ru' && locale !== 'ru' && (
          <a
            href={INSTAGRAM_URL}
            aria-label="Instagram"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.instagram)}
          >
            <Icon
              name="Instagram"
              size={ICON_SIZE_MAP[size]}
            />
          </a>
        )}
      </div>

      <div className={cn(styles.line, className)}>
        {!!DISCORD_URL && (
          <a
            href={DISCORD_URL}
            aria-label="Discord"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.discord)}
          >
            <Icon
              name="Discord"
              size={ICON_SIZE_MAP[size]}
            />
          </a>
        )}

        {!!YOUTUBE_URL && (
          <a
            href={YOUTUBE_URL}
            aria-label="YouTube"
            target="_blank"
            rel="noreferrer"
            className={cn(styles.link, styles[size], styles.youtube)}
          >
            <Icon
              name="Youtube"
              size={ICON_SIZE_MAP[size]}
            />
          </a>
        )}
      </div>
    </div>
  );
};

SocialLinks.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
  className: PropTypes.string
};

SocialLinks.defaultProps = {
  size: 'md',
  className: null
};

export default memo(SocialLinks);
