import { useMemo } from 'react';

import useLocale from './useLocale';
import useCountry from './useCountry';

const ADDRESS_GLOBAL = process.env.NEXT_PUBLIC_ADDRESS_GLOBAL || '';
const ADDRESS_RU = process.env.NEXT_PUBLIC_ADDRESS_RU || '';
const COMPANY_NAME_GLOBAL = process.env.NEXT_PUBLIC_COMPANY_NAME_GLOBAL || '';
const COMPANY_NAME_RU = process.env.NEXT_PUBLIC_COMPANY_NAME_RU || '';
const INN_NUMBER_GLOBAL = process.env.NEXT_PUBLIC_INN_NUMBER_GLOBAL || '';
const INN_NUMBER_RU = process.env.NEXT_PUBLIC_INN_NUMBER_RU || '';
const ORGNIP_NUMBER_GLOBAL = process.env.NEXT_PUBLIC_ORGNIP_NUMBER_GLOBAL || '';
const ORGNIP_NUMBER_RU = process.env.NEXT_PUBLIC_ORGNIP_NUMBER_RU || '';
const POLICY_URL_GLOBAL = process.env.NEXT_PUBLIC_POLICY_URL_GLOBAL || '';
const POLICY_URL_RU = process.env.NEXT_PUBLIC_POLICY_URL_RU || '';
const TERMS_URL_GLOBAL = process.env.NEXT_PUBLIC_TERMS_URL_GLOBAL || '';
const TERMS_URL_RU = process.env.NEXT_PUBLIC_TERMS_URL_RU || '';

const useLocaleBasedEnv = () => {
  const locale = useLocale();
  const country = useCountry();
  const isRULocale = useMemo(() => locale === 'ru', [locale]);
  const isRUCountry = useMemo(() => country === 'ru', [country]);

  const ADDRESS = useMemo(() => (
    isRULocale ? ADDRESS_RU : ADDRESS_GLOBAL
  ), [isRULocale]);
  const COMPANY_NAME = useMemo(() => (
    isRULocale ? COMPANY_NAME_RU : COMPANY_NAME_GLOBAL
  ), [isRULocale]);
  const INN_NUMBER = useMemo(() => (
    isRULocale ? INN_NUMBER_RU : INN_NUMBER_GLOBAL
  ), [isRULocale]);
  const ORGNIP_NUMBER = useMemo(() => (
    isRULocale ? ORGNIP_NUMBER_RU : ORGNIP_NUMBER_GLOBAL
  ), [isRULocale]);
  const POLICY_URL = useMemo(() => (
    isRUCountry ? POLICY_URL_RU : POLICY_URL_GLOBAL
  ), [isRUCountry]);
  const TERMS_URL = useMemo(() => (
    isRUCountry ? TERMS_URL_RU : TERMS_URL_GLOBAL
  ), [isRUCountry]);

  return {
    ADDRESS,
    COMPANY_NAME,
    INN_NUMBER,
    ORGNIP_NUMBER,
    POLICY_URL,
    TERMS_URL
  };
};

export default useLocaleBasedEnv;
