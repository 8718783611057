import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import {
  serverErrorSelector,
  serverUnavailableSelector,
  serverUnavailableDateSelector
} from '../../../redux/app/selectors';

import ErrorBanner from './ErrorBanner';

const mapStateToProps = createStructuredSelector({
  serverError: serverErrorSelector,
  serverUnavailable: serverUnavailableSelector,
  serverUnavailableDate: serverUnavailableDateSelector
});

export default connect(mapStateToProps)(ErrorBanner);
