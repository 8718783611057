import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { emailSelector } from '../../redux/profile/selectors';
import { sendAppLink } from '../../redux/software/actions';

import SendAppLinkViaEmailForm from './SendAppLinkViaEmailForm';

const mapStateToProps = createStructuredSelector({
  email: emailSelector
});

const mapDispatchToProps = {
  onSubmit: (data) => (dispatch) => dispatch(sendAppLink(data.email))
};

export default connect(mapStateToProps, mapDispatchToProps)(SendAppLinkViaEmailForm);
