import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from '../UI/Image';

import LogoImage from './LogoSymbol.svg';
import styles from './LogoSymbol.module.scss';

const LogoSymbol = ({
  size,
  className
}) => (
  <div
    className={cn(styles.root, className)}
    style={{ width: size }}
  >
    <div className={styles.image}>
      <Image
        src={LogoImage}
        alt="UP"
        layout="responsive"
        size="5vw"
        width="36"
        height="28"
      />
    </div>
  </div>
);

LogoSymbol.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

LogoSymbol.defaultProps = {
  size: 100,
  className: null
};

export default memo(LogoSymbol);
