import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Heading from '../../UI/Heading';

import styles from './CancellationPolicy.module.scss';

const CancellationPolicy = () => {
  const { t } = useTranslation('common', { keyPrefix: 'Modals.CancellationPolicy' });

  return (
    <>
      <Heading
        level="4"
        className={styles.heading}
      >
        <Trans
          t={t}
          i18nKey="title"
        />
      </Heading>

      <div className={styles.text}>
        <Trans
          t={t}
          i18nKey="text"
        />
      </div>
    </>
  );
};

CancellationPolicy.propTypes = {
};

CancellationPolicy.defaultProps = {
};

export default memo(CancellationPolicy);
