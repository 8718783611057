export const CHECK_EMAIL = 'auth/CHECK_EMAIL';
export const REGISTRATION = 'auth/REGISTRATION';
export const LOGIN = 'auth/LOGIN';
export const SET_AUTHORIZED = 'auth/SET_AUTHORIZED';
export const RESEND_EMAIL_CONFIRMATION_CODE = 'auth/RESEND_EMAIL_CONFIRMATION_CODE';
export const CONFIRM_EMAIL = 'auth/CONFIRM_EMAIL';
export const OAUTH2_LOGIN = 'auth/OAUTH2_LOGIN';
export const REQUEST_PASSWORD_RESET = 'auth/REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_RESEND = 'auth/REQUEST_PASSWORD_RESET_RESEND';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
export const LOGOUT = 'auth/LOGOUT';
