import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useCookie = (key) => {
  const [value, setValue] = useState(Cookies.get(key));

  useEffect(() => {
    const timer = setInterval(() => {
      setValue(Cookies.get(key));
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [key]);

  return value;
};

export default useCookie;
