import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';
import useBooleanState from '../../../hooks/useBooleanState';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useMounted from '../../../hooks/useMounted';
import TransitionExpand from '../TransitionExpand';
import Link from '../../Link';
import Icon from '../Icon';

import styles from './Menu.module.scss';

export const MenuHandle = memo(({ className, ...props }) => (
  <span
    {...props}
    className={cn(styles.menuHandle, className)}
  >
    <Icon
      name="Ellipsis"
      size="18"
    />
  </span>
));

MenuHandle.propTypes = {
  className: PropTypes.string
};

MenuHandle.defaultProps = {
  className: null
};

export const MenuItemButton = memo(({ className, ...props }) => (
  <button
    {...props}
    type="button"
    className={cn(styles.menuItemButton, className)}
  />
));

MenuItemButton.propTypes = {
  className: PropTypes.string
};

MenuItemButton.defaultProps = {
  className: null
};

export const MenuItemLink = memo(({ className, ...props }) => (
  <Link
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={cn(styles.menuItemLink, className)}
  />
));

MenuItemLink.propTypes = {
  className: PropTypes.string
};

MenuItemLink.defaultProps = {
  className: null
};

const Menu = memo(forwardRef(({
  handle,
  menu,
  expandDuration,
  className,
  handleClassName,
  dropdownClassName,
  menuClassName,
  onOpen,
  onClose
}, ref) => {
  const mounted = useMounted();
  const {
    isOpen,
    open,
    close,
    toggle
  } = useBooleanState();
  const [positionX, setPositionX] = useState('right');
  const [positionY, setPositionY] = useState('bottom');

  const dropdownStyle = useMemo(() => ({
    left: positionX === 'left' ? 0 : 'auto',
    right: positionX !== 'left' ? 0 : 'auto',
    top: positionY === 'top' ? 'auto' : 'calc(100% + 12px)',
    bottom: positionY !== 'top' ? 'auto' : 'calc(100% + 12px)'
  }), [positionX, positionY]);

  const rootRef = useOutsideClick(isOpen, close);
  const onCalcSize = useCallback((width, height) => {
    const rect = rootRef.current.getBoundingClientRect();
    setPositionX(rect.left + rect.width - width < 0 ? 'left' : 'right');
    setPositionY(rect.top + rect.height + height + 12 < window.innerHeight ? 'bottom' : 'top');
  }, [rootRef]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (isOpen) {
      onOpen();
    } else {
      onClose();
    }
  }, [mounted, onClose, onOpen, isOpen]);

  useImperativeHandle(ref, () => ({
    open,
    close
  }));

  return (
    <div
      ref={rootRef}
      data-open={isOpen}
      className={cn(styles.root, className)}
    >
      <button
        type="button"
        className={cn(styles.handle, handleClassName)}
        onClick={toggle}
      >
        {handle}
      </button>

      <div
        className={cn(styles.dropdown, dropdownClassName)}
        style={dropdownStyle}
      >
        <TransitionExpand
          open={isOpen}
          duration={expandDuration}
          onCalcSize={onCalcSize}
        >
          <div className={cn(styles.menu, menuClassName)}>
            {menu}
          </div>
        </TransitionExpand>
      </div>
    </div>
  );
}));

Menu.propTypes = {
  handle: PropTypes.node,
  menu: PropTypes.node,
  expandDuration: PropTypes.number,
  className: PropTypes.string,
  handleClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

Menu.defaultProps = {
  handle: null,
  menu: null,
  expandDuration: 300,
  className: null,
  handleClassName: null,
  dropdownClassName: null,
  menuClassName: null,
  onOpen: noop,
  onClose: noop
};

export default Menu;
