export const FBPageView = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'PageView');
};

export const FBCompleteRegistration = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'CompleteRegistration');
};

export const FBLead = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'Lead');
};

export const FBSubscribe = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'Subscribe');
};

export const FBPurchase = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'Purchase');
};

export const FBInitiateCheckout = () => {
  if (typeof window === 'undefined' || typeof window.fbq !== 'function') {
    return;
  }

  window.fbq('track', 'InitiateCheckout');
};
