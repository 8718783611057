import { useRef, useEffect, useCallback } from 'react';

const useOutsideClick = (active, onOutsideClick) => {
  const ref = useRef(null);
  const prevent = useRef(false);

  const onClick = useCallback(({ target }) => {
    if (!ref.current || ref.current.contains(target) || prevent.current) {
      prevent.current = false;
      return;
    }

    onOutsideClick();
  }, [onOutsideClick]);

  const onMouseDown = useCallback(({ target }) => {
    if (ref.current && ref.current.contains(target)) {
      prevent.current = true;
    } else {
      prevent.current = false;
    }
  }, []);

  useEffect(() => {
    if (active) {
      document.addEventListener('click', onClick, false);
      document.addEventListener('mousedown', onMouseDown, false);
    }

    return () => {
      if (active) {
        document.removeEventListener('click', onClick, false);
        document.removeEventListener('mousedown', onMouseDown, false);
      }
    };
  }, [active, onClick, onMouseDown]);

  return ref;
};

export default useOutsideClick;
