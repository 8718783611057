import { isSuccessAction, createActionRequestWithAuth } from '../utils';
import { gaSendEvent } from '../../utils/ga';
import {
  GET_SUBSCRIPTIONS,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION
} from './constants';

export const getSubscriptions = () => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_SUBSCRIPTIONS,
  request: {
    method: 'GET',
    endpoint: 'subscriptions'
  }
}));

export const createSubscription = ({ uid, provider, coupon }) => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: CREATE_SUBSCRIPTION,
    request: {
      method: 'POST',
      endpoint: 'subscriptions',
      data: {
        priceUid: uid,
        provider,
        coupon
      }
    }
  }));

  if (isSuccessAction(action)) {
    gaSendEvent('subscription_created');
  }

  return action;
};

export const cancenSubscription = (uid, { reason, comment }) => (dispatch) => (
  dispatch(createActionRequestWithAuth({
    type: CANCEL_SUBSCRIPTION,
    request: {
      method: 'POST',
      endpoint: `subscriptions/${uid}/cancel`,
      data: {
        reason,
        comment
      }
    }
  }))
);
