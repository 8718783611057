import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { hasSubscriptionSelector } from '../../../redux/profile/selectors';

import FullNav from './FullNav';

const mapStateToProps = createStructuredSelector({
  hasSubscription: hasSubscriptionSelector
});

export default connect(mapStateToProps)(FullNav);
