import {
  memo,
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import { noop } from '../../../utils';

const TransitionExpand = ({
  open,
  duration,
  minHeight,
  mount,
  className,
  onCalcSize,
  children
}) => {
  const ref = useRef(null);
  const animationFrame = useRef(null);
  const [height, setHeight] = useState(open ? null : minHeight);

  const style = useMemo(() => {
    const transition = typeof height === 'number';

    return {
      height,
      overflow: transition ? 'hidden' : null,
      transition: transition ? `height ${duration}ms linear` : null
    };
  }, [
    height,
    duration
  ]);

  const onEnter = useCallback(() => {
    onCalcSize(ref.current.scrollWidth, ref.current.scrollHeight);
    setHeight(minHeight);
  }, [minHeight, onCalcSize]);

  const onEntering = useCallback(() => {
    animationFrame.current = requestAnimationFrame(() => {
      setHeight(ref.current.scrollHeight);
    });
  }, []);

  const onEntered = useCallback(() => {
    if (mount) {
      setHeight(null);
    }
  }, [mount]);

  const onExit = useCallback(() => {
    setHeight(ref.current.offsetHeight);
  }, []);

  const onExiting = useCallback(() => {
    animationFrame.current = requestAnimationFrame(() => {
      setHeight(minHeight);
    });
  }, [minHeight]);

  const onExited = useCallback(() => {
    if (mount) {
      setHeight(null);
    }
  }, [mount]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }

    return () => {
      cancelAnimationFrame(animationFrame.current);
    };
  }, []);

  return (
    <Transition
      mountOnEnter={mount}
      unmountOnExit={mount}
      in={open}
      nodeRef={ref}
      timeout={duration}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExited={onExited}
      onExiting={onExiting}
    >
      <div
        ref={ref}
        style={style}
        className={className}
      >
        {children}
      </div>
    </Transition>
  );
};

TransitionExpand.propTypes = {
  open: PropTypes.bool,
  duration: PropTypes.number,
  minHeight: PropTypes.number,
  mount: PropTypes.bool,
  className: PropTypes.string,
  onCalcSize: PropTypes.func,
  children: PropTypes.node
};

TransitionExpand.defaultProps = {
  open: false,
  duration: 100,
  minHeight: 0,
  mount: true,
  className: null,
  onCalcSize: noop,
  children: null
};

export default memo(TransitionExpand);
