import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import { noop } from '../../utils';
import Icon from '../UI/Icon';

import styles from './Notifications.module.scss';

const TRANSITION_CLASS_NAMES = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

const ICON_NAME_MAP = {
  success: 'CheckBold',
  info: 'Info',
  warning: 'Warning',
  error: 'Blocked'
};
const ICON_SIZE_MAP = {
  success: 15,
  info: 20,
  warning: 19,
  error: 20
};

export const Notification = ({
  id,
  type,
  text,
  showDuration,
  transitionDuration,
  onClose
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'Notification' });
  const ref = useRef(null);
  const timer = useRef(null);
  const [show, setShow] = useState(true);

  const icon = useMemo(() => ICON_NAME_MAP[type], [type]);
  const iconSize = useMemo(() => ICON_SIZE_MAP[type], [type]);
  const transitionStyle = useMemo(() => ({
    transition: `transform ${transitionDuration}ms ease-in-out, opacity ${transitionDuration}ms ease-in-out`
  }), [transitionDuration]);

  const onCloseClick = useCallback(() => setShow(false), []);
  const onExited = useCallback(() => onClose(id), [onClose, id]);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setShow(false);
    }, showDuration);

    return () => {
      clearTimeout(timer.current);
    };
  }, [showDuration]);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={transitionDuration}
      nodeRef={ref}
      classNames={TRANSITION_CLASS_NAMES}
      onExited={onExited}
    >
      <div
        ref={ref}
        className={styles.inner}
        style={transitionStyle}
      >
        <div
          className={cn(styles.notification, {
            [styles[type]]: styles[type]
          })}
        >
          <div className={styles.inner}>
            {icon && (
              <div className={styles.icon}>
                <Icon
                  name={icon}
                  size={iconSize}
                />
              </div>
            )}

            <div className={styles.text}>
              {text}
            </div>
          </div>

          <button
            type="button"
            aria-label={t('close')}
            title={t('close')}
            className={styles.closeButton}
            onClick={onCloseClick}
          >
            <Icon
              name="Cross"
              size={10}
            />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf([
    'success',
    'info',
    'warning',
    'error'
  ]),
  text: PropTypes.node,
  showDuration: PropTypes.number,
  transitionDuration: PropTypes.number,
  onClose: PropTypes.func
};

Notification.defaultProps = {
  type: 'info',
  text: null,
  showDuration: 7000,
  transitionDuration: 500,
  onClose: noop
};

const Notifications = ({
  list,
  showDuration,
  transitionDuration,
  onClose
}) => (
  <div className={styles.root}>
    {list.map(({ id, type, text }) => (
      <Notification
        key={id}
        id={id}
        type={type}
        text={text}
        showDuration={showDuration}
        transitionDuration={transitionDuration}
        onClose={onClose}
      />
    ))}
  </div>
);

Notifications.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf([
      'success',
      'info',
      'warning',
      'error'
    ]),
    text: PropTypes.node
  })),
  showDuration: PropTypes.number,
  transitionDuration: PropTypes.number,
  onClose: PropTypes.func
};

Notifications.defaultProps = {
  list: [],
  showDuration: 7000,
  transitionDuration: 500,
  onClose: noop
};

export default memo(Notifications);
