import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  modalDataSelector,
  modalSelector
} from '../../redux/app/selectors';
import { setModal } from '../../redux/app/actions';

import ModalManager from './ModalManager';

const mapStateToProps = createStructuredSelector({
  modal: modalSelector,
  data: modalDataSelector
});

const mapDispatchToProps = {
  onClose: () => (dispatch) => dispatch(setModal(null))
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
