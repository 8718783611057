import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Hidden.module.scss';

const Hidden = ({
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  tag,
  className,
  children
}) => {
  const Tag = useMemo(() => tag || 'div', [tag]);

  return (
    <Tag
      className={cn({
        [styles.xxs]: xxs,
        [styles.xs]: xs,
        [styles.sm]: sm,
        [styles.md]: md,
        [styles.lg]: lg,
        [styles.xl]: xl,
        [styles.xxl]: xxl
      }, className)}
    >
      {children}
    </Tag>
  );
};

Hidden.propTypes = {
  xxs: PropTypes.bool,
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xxl: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

Hidden.defaultProps = {
  xxs: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  tag: 'div',
  className: null,
  children: null
};

export default memo(Hidden);
