import { HYDRATE } from 'next-redux-wrapper';
import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer,
  createRequestReducer
} from '../utils';
import {
  GET_POSTS_CATEGORTIES,
  GET_ARTICLE_LIST,
  GET_ARTICLE_MEDIA,
  SET_SEARCH,
  SET_SORTING
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  search: '',
  sorting: 'byCreatedAt'
};

const articlesList = createReducer(initialState, {
  [GET_ARTICLE_LIST]: defaultFetchReducer,

  [GET_ARTICLE_MEDIA]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true
    }),

    SUCCESS: () => ({
      ...state,
      fetching: false,
      articleMedia: action.request.response.data || null
    }),

    FAIL: () => ({
      ...state,
      fetching: false
    })
  }),

  [GET_POSTS_CATEGORTIES]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true
    }),

    SUCCESS: () => ({
      ...state,
      fetching: false,
      postCategories: action.request.response.data || null
    }),

    FAIL: () => ({
      ...state,
      fetching: false
    })
  }),

  [SET_SEARCH]: (state, { search }) => ({
    ...state,
    search
  }),

  [SET_SORTING]: (state, { sorting }) => ({
    ...state,
    sorting
  }),

  [HYDRATE]: (state, action) => ({
    ...state,
    ...action.payload.articlesList
  })
});

export default articlesList;
