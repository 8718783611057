import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension';

import createApiMiddleware from './middlewares/createApiMiddleware';
import createAuthMiddleware from './middlewares/createAuthMiddleware';
import createApiErrorsMiddleware from './middlewares/createApiErrorsMiddleware';

import { setServerUnavailable, setServerError } from './app/actions';
import { onUnauthorized } from './auth/actions';
import { addErrorNotification } from './notifications/actions';

import app from './app/reducer';
import article from './article/reducer';
import articlesList from './articlesList/reducer';
import auth from './auth/reducer';
import checkout from './checkout/reducer';
import coupon from './coupon/reducer';
import game from './game/reducer';
import gamesList from './gamesList/reducer';
import health from './health/reducer';
import locations from './locations/reducer';
import notifications from './notifications/reducer';
import plansList from './plansList/reducer';
import profile from './profile/reducer';
import subscriptions from './subscriptions/reducer';

const onError = (action) => (dispatch) => {
  const disable = action.request?.disableErrorNotification;
  const response = action.request?.response;
  const statusCode = response?.statusCode;
  const code = response?.data?.code;
  const message = response?.data?.message;

  if (disable) {
    return;
  }

  if (statusCode === 401 || statusCode === 422 || code === 401 || code === 422) {
    return;
  }

  if (response.statusCode === 503 || code === 503) {
    const retryAfter = response?.headers?.get('Retry-After');
    dispatch(setServerUnavailable(retryAfter));
    return;
  }

  if (/^5\d\d$/.test(response.statusCode) || /^5\d\d$/.test(code)) {
    dispatch(setServerError());
    return;
  }

  if (message) {
    dispatch(addErrorNotification(message));
  }
};

const createRootReducer = combineReducers({
  app,
  article,
  articlesList,
  auth,
  checkout,
  coupon,
  game,
  gamesList,
  health,
  locations,
  notifications,
  plansList,
  profile,
  subscriptions
});

export const makeStore = () => {
  const middlewares = [
    thunkMiddleware,
    createApiMiddleware(),
    createAuthMiddleware(onUnauthorized),
    createApiErrorsMiddleware(onError)
  ];

  const composeEnhancers = composeWithDevTools({ name: 'FPSBOOSTER-WEBAPP' });
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(
    createRootReducer,
    enhancers
  );
};

const wrapper = createWrapper(makeStore);

export const getInitialStore = () => makeStore();

export default wrapper;
