import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './constants';

export const addNotification = (type, text) => ({
  type: ADD_NOTIFICATION,
  data: {
    type,
    text
  }
});

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  id
});

export const addSuccessNotification = (text) => addNotification('success', text);
export const addInformationNotification = (text) => addNotification('info', text);
export const addWarningNotification = (text) => addNotification('warning', text);
export const addErrorNotification = (text) => addNotification('error', text);
