import ls from 'local-storage';
import { redirect } from '../../i18n/redirect';

import { SET_PROCESSING } from './constants';

import { isSuccessAction } from '../utils';
import { ymSendData, ymSendPurchase } from '../../utils/ym';
import { gaSendEvent, gaSendPurchase } from '../../utils/ga';
import {
  FBSubscribe,
  FBPurchase
} from '../../utils/fb';
import { getItemLabelByPlan, getSavedLocale } from '../../utils';
import BroadcastChannel from '../../utils/BroadcastChannel';
import cloudPaymentsPayment from './cloudPayments';

import { processingSelector } from './selectors';
import { emailSelector, hasSubscriptionSelector } from '../profile/selectors';

import { createSubscription } from '../subscriptions/actions';
import {
  createPayzeCheckout,
  createPaypalCheckout,
  createStripeCheckout,
  createXsollaCheckout
} from '../payments/actions';
import { getProfile } from '../profile/actions';
import { setModal } from '../app/actions';
import { couponNameSelector } from '../coupon/selectors';
import { addErrorNotification } from '../notifications/actions';

const setPaymentProcessing = (processing) => ({
  type: SET_PROCESSING,
  processing
});

export const onPaymentFailed = (dispatch, plan, provider, reason) => {
  dispatch(setPaymentProcessing(false));

  if (reason === 'sdk-not-found') {
    dispatch(addErrorNotification('Browser Error: SDK for this provider didn\'t load'));
  }

  if (['paypal', 'payze'].includes(provider)) {
    dispatch(setModal('PaymentFailed', { reason, plan, provider }));
  }

  return {
    status: 'FAIL',
    reason: 'processing'
  };
};

export const onPaymentSuccess = (dispatch, item, invoice, coupon) => {
  dispatch(setPaymentProcessing(false));
  ymSendPurchase(item, invoice, coupon);
  gaSendPurchase(item, invoice, coupon);
  ymSendData('reachGoal', 'subscribe');
  FBSubscribe();
  FBPurchase();
  redirect(`/purchase/thanks/?subscription=${invoice.subscriptionUid}`);

  return {
    status: 'SUCCESS'
  };
};

export const finishPayment = (result, context) => async(dispatch, getState) => {
  const {
    provider, plan, invoice, coupon
  } = context;

  await dispatch(getProfile());
  const hasSubscription = hasSubscriptionSelector(getState());

  if (result.status === 'SUCCESS' || hasSubscription) {
    return onPaymentSuccess(dispatch, plan, invoice, coupon);
  }

  return onPaymentFailed(dispatch, plan, provider, result.reason);
};

// eslint-disable-next-line import/prefer-default-export
export const createPayment = (plan, provider) => async(dispatch, getState) => {
  const state = getState();
  const processing = processingSelector(state);

  if (processing || !plan || !plan.uid || !provider) {
    return null;
  }

  dispatch(setPaymentProcessing(true));

  const { uid, withTrial } = plan;
  const email = emailSelector(state);
  const coupon = couponNameSelector(state);
  const createSubscriptionAction = await dispatch(createSubscription({ uid, provider, coupon }));
  const invoice = createSubscriptionAction?.request?.response?.data?.invoice;
  const subscription = createSubscriptionAction?.request?.response?.data?.subscription;

  if (!isSuccessAction(createSubscriptionAction) || !invoice || !subscription) {
    return onPaymentFailed(dispatch, plan, provider, 'server-error');
  }

  const context = {
    provider, plan, invoice, coupon
  };

  invoice.subscriptionUid = subscription.uid;
  let result;

  gaSendEvent('begin_checkout', {
    currency: invoice.price.currency.code,
    value: invoice.amount,
    items: [
      {
        item_id: uid,
        currency: invoice.price.currency.code,
        price: invoice.amount,
        quantity: 1
      }
    ]
  });

  if (provider === 'cloudpayments') {
    const locale = getSavedLocale();
    const itemLabel = getItemLabelByPlan(plan.intervalDays);

    result = await cloudPaymentsPayment({
      invoiceId: invoice.id,
      accountId: invoice.userId,
      accountEmail: email,
      amount: invoice.amount,
      currency: invoice.price.currency.code,
      withTrial,
      locale,
      itemLabel
    });
  } else if (provider === 'payze') {
    const getPurchaseUrlAction = await dispatch(createPayzeCheckout(invoice.id));
    const paymentUrl = getPurchaseUrlAction?.request?.response?.data?.url;

    if (!isSuccessAction(getPurchaseUrlAction) || !paymentUrl) {
      return onPaymentFailed(dispatch, plan, provider, 'server-error');
    }

    result = await new Promise((resolve) => {
      const bc = new BroadcastChannel('iframe_checkout');

      bc.addEventListener('message', ({ data }) => {
        resolve(JSON.parse(data));
        bc.close();
        dispatch(setModal(null));
      });

      dispatch(setModal('IframeCheckout', { url: paymentUrl }));
    });
  } else if (provider === 'paypal') {
    const getPurchaseUrlAction = await dispatch(createPaypalCheckout(subscription.uid));
    const paymentUrl = getPurchaseUrlAction?.request?.response?.data?.linkApprove;

    if (!isSuccessAction(getPurchaseUrlAction) || !paymentUrl) {
      return onPaymentFailed(dispatch, plan, provider, 'server-error');
    }

    ls.set('paypal_checkout_plan', plan);
    ls.set('paypal_checkout_invoice', invoice);
    window.location.href = paymentUrl;
    return Promise.resolve({});
  } else if (provider === 'stripe') {
    const getPurchaseUrlAction = await dispatch(createStripeCheckout(subscription.uid));
    const paymentUrl = getPurchaseUrlAction?.request?.response?.data?.url;

    if (!isSuccessAction(getPurchaseUrlAction) || !paymentUrl) {
      return onPaymentFailed(dispatch, plan, provider, 'server-error');
    }

    ls.set('stripe_checkout_plan', plan);
    ls.set('stripe_checkout_invoice', invoice);
    window.location.href = paymentUrl;
    return Promise.resolve({});
  } else if (provider === 'xsolla') {
    const getPurchaseUrlAction = await dispatch(createXsollaCheckout(subscription.uid));
    const data = getPurchaseUrlAction?.request?.response?.data;

    if (!isSuccessAction(getPurchaseUrlAction) || !data?.accessToken) {
      return onPaymentFailed(dispatch, plan, provider, 'server-error');
    }

    if (!window.XPayStationWidget) {
      return onPaymentFailed(dispatch, plan, provider, 'sdk-not-found');
    }

    const { accessToken, env } = data;

    window.XPayStationWidget.init({
      access_token: accessToken,
      sandbox: env !== 'production'
    });

    window.XPayStationWidget.on('close', function handleXsollaClose() {
      window.XPayStationWidget.off('close', handleXsollaClose);

      onPaymentFailed(dispatch, plan, provider, 'close');
    });

    window.XPayStationWidget.open();
    window.XPayStationWidget.on('status-done', () => {
      finishPayment({ status: 'SUCCESS' }, context);
    });

    return Promise.resolve({});
  } else {
    return onPaymentFailed(dispatch, plan, provider, 'unknown-provider');
  }

  return dispatch(finishPayment(result, context));
};
