import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Heading.module.scss';

const Heading = ({
  level,
  tag,
  responsive,
  weight,
  align,
  color,
  className,
  children,
  ...props
}) => {
  const Tag = useMemo(() => {
    if (tag) {
      return tag;
    }

    if (`${level}` === '0') {
      return 'h1';
    }

    return `h${level}`;
  }, [level, tag]);

  return (
    <Tag
      className={cn(styles.root, {
        [styles[`h${level}`]]: styles[`h${level}`],
        [styles.responsive]: responsive,
        [styles[`weight_${weight}`]]: weight && styles[`weight_${weight}`],
        [styles[`align_${align}`]]: align && styles[`align_${align}`],
        [styles[`color_${color}`]]: color && styles[`color_${color}`]
      }, className)}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([
    0, 1, 2, 3, 4, 5, 6,
    '0', '1', '2', '3', '4', '5', '6'
  ]),
  tag: PropTypes.oneOf([
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div'
  ]),
  responsive: PropTypes.bool,
  weight: PropTypes.oneOf([
    500, 600, 700, 800,
    '500', '600', '700', '800'
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.oneOf([
    1, 2, 3, 4, 5,
    '1', '2', '3', '4', '5'
  ]),
  className: PropTypes.string,
  children: PropTypes.node
};

Heading.defaultProps = {
  level: 1,
  tag: null,
  responsive: false,
  weight: null,
  align: null,
  color: null,
  className: null,
  children: null
};

export default memo(Heading);
