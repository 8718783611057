import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './Requisites.module.scss';

const Requisites = () => {
  const { t } = useTranslation('layout', { keyPrefix: 'Requisites' });

  return (
    <div className={styles.root}>
      <Trans
        t={t}
        i18nKey="text"
      />
    </div>
  );
};

export default memo(Requisites);
