import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useCookie from './useCookie';
import { countryCodeSelector as profileCountrySelector } from '../redux/profile/selectors';
import { currentCountryCodeSelector as detectedCountrySelector } from '../redux/locations/selectors';

const useCountry = () => {
  const [current, setCurrent] = useState(null);
  const cookieCountry = useCookie('Country');
  const profileCountry = useSelector(profileCountrySelector);
  const detectedCountry = useSelector(detectedCountrySelector);

  const country = useMemo(() => {
    const code = profileCountry || cookieCountry || detectedCountry;
    return code ? code.toLowerCase() : null;
  }, [detectedCountry, cookieCountry, profileCountry]);

  useEffect(() => {
    setCurrent(country);
  }, [country]);

  return current;
};

export default useCountry;
