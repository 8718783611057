import { useEffect, useState, useMemo } from 'react';
import Bowser from 'bowser';

const useIsWindows = () => {
  const [os, setOs] = useState(null);

  useEffect(() => {
    const browser = Bowser.parse(window.navigator.userAgent);

    setOs(browser.os?.name || null);
  }, []);

  const isWindows = useMemo(() => os === 'Windows', [os]);

  return isWindows;
};

export default useIsWindows;
