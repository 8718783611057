import { createActionRequestWithAuth } from '../utils';
import {
  CREATE_PAYZE_CHECKOUT,
  CREATE_PAYPAL_CHECKOUT,
  CREATE_STRIPE_CHECKOUT,
  CREATE_XSOLLA_CHECKOUT
} from './constants';

export const createPayzeCheckout = (invoiceId) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_PAYZE_CHECKOUT,
  request: {
    method: 'POST',
    endpoint: 'payments/payze/checkout',
    data: { invoiceId }
  }
}));

export const createPaypalCheckout = (subscriptionUid) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_PAYPAL_CHECKOUT,
  request: {
    method: 'POST',
    endpoint: 'payments/paypal/subscriptions',
    data: { subscriptionUid }
  }
}));

export const createStripeCheckout = (subscriptionUid) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_STRIPE_CHECKOUT,
  request: {
    method: 'POST',
    endpoint: 'payments/stripe/checkout',
    data: { subscriptionUid }
  }
}));

export const createXsollaCheckout = (subscriptionUid) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_XSOLLA_CHECKOUT,
  request: {
    method: 'POST',
    endpoint: 'payments/xsolla/checkout',
    data: { subscriptionUid }
  }
}));
