import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';

import preloader from './preloader.svg';

const Image = ({
  alt,
  src,
  width,
  height,
  layout,
  objectFit,
  objectPosition,
  priority,
  lazyRoot,
  quality,
  sizes,
  gradientPreloader,
  onLoadingComplete
}) => {
  const resSrc = useMemo(() => src?.src || src, [src]);
  const unoptimized = useMemo(() => (
    typeof src === 'string' && !gradientPreloader
  ), [gradientPreloader, src]);
  const blurDataURL = useMemo(() => {
    if (gradientPreloader) {
      return preloader;
    }

    return src?.placeholder || null;
  }, [gradientPreloader, src]);
  const placeholder = useMemo(() => (
    src?.placeholder || gradientPreloader ? 'blur' : null
  ), [gradientPreloader, src?.placeholder]);

  const loader = useCallback((params) => {
    if (src === 'string') {
      return params.src;
    }

    if (!Array.isArray(src.images)) {
      return `${params.src}?w=${params.width}`;
    }

    const image = src.images.find((item, i) => {
      const next = src.images[i + 1];

      if (!next) {
        return true;
      }

      if (!i && params.width < item.width) {
        return true;
      }

      return params.width >= item.width && params.width <= next.width;
    });

    if (image) {
      return `${image.path}?w=${params.width}`;
    }

    return params.src;
  }, [src]);

  return (
    <NextImage
      src={resSrc}
      alt={alt}
      width={width}
      height={height}
      loader={loader}
      unoptimized={unoptimized}
      blurDataURL={blurDataURL}
      placeholder={placeholder}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
      priority={priority}
      lazyBoundary="1000px"
      lazyRoot={lazyRoot}
      quality={quality}
      sizes={sizes}
      onLoadingComplete={onLoadingComplete}
    />
  );
};

const SizePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

Image.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
      blurDataURL: PropTypes.string
    })
  ]).isRequired,
  alt: PropTypes.string,
  width: SizePropType,
  height: SizePropType,
  layout: PropTypes.oneOf([
    'fill',
    'fixed',
    'intrinsic',
    'responsive',
    'raw'
  ]),
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  priority: PropTypes.bool,
  lazyRoot: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.node }),
    PropTypes.object
  ]),
  quality: SizePropType,
  sizes: PropTypes.string,
  gradientPreloader: PropTypes.bool,
  onLoadingComplete: PropTypes.func
};

Image.defaultProps = {
  alt: undefined,
  width: undefined,
  height: undefined,
  layout: undefined,
  objectFit: undefined,
  objectPosition: undefined,
  priority: undefined,
  lazyRoot: undefined,
  quality: undefined,
  sizes: undefined,
  gradientPreloader: false,
  onLoadingComplete: undefined
};

export default memo(Image);
