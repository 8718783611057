import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import {
  GET_COUPON,
  COUPON_CLEAR
} from './constants';

const initialState = {
  ...defaultFetchDataState,
  name: null
};

const coupon = createReducer(initialState, {
  [GET_COUPON]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => {
      const { hasPriceRestrictions, prices } = action.request.response.data;

      if (hasPriceRestrictions && !prices.find(({ uid }) => action.uids.includes(uid))) {
        return {
          ...state,
          fetching: false
        };
      }

      return {
        ...state,
        fetching: false,
        data: action.request.response.data,
        name: action.name
      };
    }
  }),

  [COUPON_CLEAR]: () => ({ ...initialState })
});

export default coupon;
