import {
  memo,
  useRef,
  useCallback,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { noop } from '../../../utils';
import IconButton from '../IconButton';

import styles from './Modal.module.scss';

if (typeof(window) !== 'undefined') {
  ReactModal.setAppElement('#__next');
}

const Modal = ({
  open,
  shouldCloseOnOverlayClick,
  hideCloseButton,
  disablePadding,
  className,
  overlayClassName,
  bodyOpenClassName,
  closeButtonClassName,
  style,
  onClose,
  onAfterClose,
  children
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'Modal' });
  const modalRef = useRef(null);

  const onAfterOpen = useCallback(() => {
    if (!modalRef.current) {
      return;
    }

    disableBodyScroll(modalRef.current);
  }, []);

  const onRequestClose = useCallback(() => {
    onClose();

    if (modalRef.current) {
      enableBodyScroll(modalRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [onClose]);

  const overlayRef = useCallback((element) => {
    modalRef.current = element;
  }, []);

  useEffect(() => {
    if (open) {
      if (modalRef.current) {
        disableBodyScroll(modalRef.current);
      }
    } else if (modalRef.current) {
      enableBodyScroll(modalRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open]);

  useEffect(() => () => {
    if (modalRef.current) {
      enableBodyScroll(modalRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, []);

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={open}
      className={cn(styles.modal, {
        [styles.disablePadding]: disablePadding
      }, className)}
      style={style}
      overlayClassName={cn(styles.overlay, overlayClassName)}
      bodyOpenClassName={bodyOpenClassName}
      overlayRef={overlayRef}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
    >
      {!hideCloseButton && (
        <IconButton
          icon="CrossThin"
          theme="dark"
          title={t('closeButtonLabel')}
          aria-label={t('closeButtonLabel')}
          className={cn(styles.closeButton, closeButtonClassName)}
          onClick={onRequestClose}
        />
      )}

      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  disablePadding: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  overlayClassName: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  onClose: PropTypes.func,
  onAfterClose: PropTypes.func,
  children: PropTypes.node
};

Modal.defaultProps = {
  open: false,
  shouldCloseOnOverlayClick: true,
  hideCloseButton: false,
  disablePadding: false,
  className: null,
  style: {},
  overlayClassName: null,
  bodyOpenClassName: null,
  closeButtonClassName: null,
  onClose: noop,
  onAfterClose: undefined,
  children: null
};

export default memo(Modal);
