import { memo } from 'react';
import PropTypes from 'prop-types';

import Container from '../../UI/Container';
import Hidden from '../../UI/Hidden';
import Visible from '../../UI/Visible';
import FullNav from '../FullNav';
import LangSwitch from '../LangSwitch';
import CountrySwitch from '../CountrySwitch';
import ScrollUpButton from '../ScrollUpButton';
import SeoText from '../SeoText';
import Copyright from '../Copyright';
import Requisites from '../Requisites';

import styles from './Footer.module.scss';

const Footer = ({
  seoTitle,
  seoText
}) => (
  <Container className={styles.root}>
    <div className={styles.seoText}>
      <SeoText
        title={seoTitle}
        text={seoText}
      />
    </div>

    <div className={styles.fullNav}>
      <FullNav />
    </div>

    <div className={styles.bottom}>
      <Visible
        xs
        sm
        className={styles.langMobileContainer}
      >
        <div className={styles.langMobile}>
          <LangSwitch />
        </div>
      </Visible>

      <div className={styles.requisites}>
        <Requisites />
      </div>

      <div className={styles.copyrightBox}>
        <div className={styles.copyright}>
          <Copyright />
        </div>

        <div className={styles.controls}>
          <Hidden
            xs
            sm
          >
            <LangSwitch />
          </Hidden>
          <Hidden
            xs
            sm
          >
            <CountrySwitch />
          </Hidden>
          <div className={styles.scrollUpButton}>
            <ScrollUpButton />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

Footer.propTypes = {
  seoTitle: PropTypes.string,
  seoText: PropTypes.string
};

Footer.defaultProps = {
  seoTitle: null,
  seoText: null
};

export default memo(Footer);
