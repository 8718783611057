import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  fetchingSelector,
  countriesSelector,
  countryOptionsSelector
} from '../../redux/locations/selectors';
import { getCountriesList } from '../../redux/locations/actions';

import CountrySelectModal from './CountrySelectModal';

const listFetchingSelector = createSelector(
  fetchingSelector,
  countriesSelector,
  (fetching, countries) => fetching && (!Array.isArray(countries) || !countries.length)
);

const mapStateToProps = createStructuredSelector({
  listFetching: listFetchingSelector,
  countryOptions: countryOptionsSelector
});

const mapDispatchToProps = {
  onMount: getCountriesList
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelectModal);
