import {
  memo,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setConfirmCountry } from '../../../redux/app/actions';
import { getCountriesList } from '../../../redux/locations/actions';
import useCountry from '../../../hooks/useCountry';

import Icon from '../../UI/Icon';

import styles from './CountrySwitch.module.scss';

const CountrySwitch = ({ ready, countryList }) => {
  const dispatch = useDispatch();
  const country = useCountry();

  const onClickHandle = useCallback(() => {
    dispatch(setConfirmCountry(true));
  }, [dispatch]);

  useEffect(() => {
    if (!countryList.length) {
      dispatch(getCountriesList());
    }
  }, [dispatch, countryList]);

  const currentCountryLabel = useMemo(() => {
    if (!country || !countryList) {
      return 'Unknown';
    }

    const currentCountry = countryList.find(({ value }) => value?.toLowerCase() === country);
    if (!currentCountry?.name) {
      return 'Unknown #2';
    }

    return currentCountry.name.length < 8
      ? currentCountry.name
      : currentCountry.value;
  }, [country, countryList]);

  if (!ready) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.switcher}
      onClick={onClickHandle}
    >
      <Icon
        name="GlobeThin"
        size="20"
        className={styles.icon}
      />
      <span className={styles.text}>
        {currentCountryLabel}
      </span>
    </button>
  );
};

CountrySwitch.propTypes = {
  ready: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }))
};

CountrySwitch.defaultProps = {
  ready: false,
  countryList: []
};

export default memo(CountrySwitch);
