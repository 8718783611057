import Cookies from 'js-cookie';
import { GET_PLANS_LIST, CLEAR_PLANS_LIST } from './constants';
import { authorizedSelector } from '../auth/selectors';

import { pricesUidsSelector } from './selectors';
import { dataSelector as couponSelector } from '../coupon/selectors';
import { clearCoupon } from '../coupon/actions';

export const getPlansList = () => async(dispatch, getState) => {
  const country = Cookies.get('Country');
  const authorized = authorizedSelector(getState());
  const queryParams = country && !authorized ? { country_code: country } : null;

  const action = await dispatch({
    type: GET_PLANS_LIST,
    request: {
      endpoint: 'subscriptions/prices',
      queryParams
    }
  });

  const coupon = couponSelector(getState());
  const uids = pricesUidsSelector(getState());

  if (coupon && coupon.hasPriceRestrictions && !coupon.prices.find(({ uid }) => uids.includes(uid))) {
    dispatch(clearCoupon());
  }

  return action;
};

export const clearPlansList = () => ({
  type: CLEAR_PLANS_LIST
});
