import { createReducer, createRequestReducer } from '../utils';
import {
  CHECK_EMAIL,
  REGISTRATION,
  LOGIN,
  SET_AUTHORIZED,
  CONFIRM_EMAIL,
  OAUTH2_LOGIN,
  RESET_PASSWORD,
  LOGOUT
} from './constants';

const initialState = {
  email: null,
  isRegistered: false,
  hasPassword: false,
  ready: false,
  authorized: false
};

const authDefaultReducer = (state, action) => createRequestReducer(state, action, {
  SUCCESS: () => {
    if (!action.request.response?.data?.accessToken) {
      return state;
    }

    return {
      ...state,
      email: null,
      isRegistered: false,
      hasPassword: false,
      ready: true,
      authorized: !!action.request.response?.data?.accessToken
    };
  }
});

const auth = createReducer(initialState, {
  [CHECK_EMAIL]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      email: action.request.queryParams.email,
      isRegistered: action.request.response.data.isRegistered,
      hasPassword: action.request.response.data.hasPassword
    })
  }),

  [REGISTRATION]: authDefaultReducer,

  [LOGIN]: authDefaultReducer,

  [CONFIRM_EMAIL]: authDefaultReducer,

  [SET_AUTHORIZED]: (state, { authorized }) => ({
    ...state,
    ready: true,
    authorized
  }),

  [RESET_PASSWORD]: authDefaultReducer,

  [OAUTH2_LOGIN]: authDefaultReducer,

  [LOGOUT]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      email: null,
      ready: true,
      isRegistered: false,
      hasPassword: false,
      authorized: false
    })
  })
});

export default auth;
