const LANGUAGES = {
  en: 'en-US',
  ru: 'ru-RU'
};
const CHARGE_SCHEMA_DESCRIPTION = '';
const AUTH_SCHEMA_DESCRIPTION = 'Для проверки введенных данных сервис приема платежей '
                              + 'заморозит, а затем автоматически вернет обратно';

const createCloudPaymentWidgetParams = ({
  invoiceId,
  accountId,
  accountEmail,
  description,
  amount,
  currency,
  itemLabel
}) => ({
  publicId: process.env.NEXT_PUBLIC_CLOUDPAYMENTS_PUBLIC_ID,
  invoiceId,
  amount,
  currency,
  accountId,
  description,
  email: accountEmail,
  requireEmail: true,
  data: {
    cloudPayments: {
      CustomerReceipt: {
        Items: [
          {
            label: itemLabel,
            price: amount,
            quantity: 1,
            amount,
            method: 1,
            vat: null,
            object: 4
          }
        ],
        TaxationSystem: 1,
        Email: accountEmail,
        Amounts: {
          Electronic: amount
        }
      }
    }
  },
  skin: 'classic',
  retryPayment: true
});

const cloudPaymentsPayment = ({
  invoiceId,
  accountId,
  accountEmail,
  amount,
  currency,
  withTrial,
  locale,
  itemLabel
}) => {
  const schema = withTrial ? 'auth' : 'charge';
  const params = createCloudPaymentWidgetParams({
    invoiceId,
    accountId,
    accountEmail,
    description: schema === 'auth' ? AUTH_SCHEMA_DESCRIPTION : CHARGE_SCHEMA_DESCRIPTION,
    amount,
    currency,
    itemLabel
  });
  const widget = new window.cp.CloudPayments({
    language: LANGUAGES[locale]
  });
  return new Promise((resolve) => {
    widget.pay(schema, params, {
      onSuccess(options) {
        resolve({ status: 'SUCCESS', options });
      },
      onFail(reason, options) {
        resolve({ status: 'FAIL', reason, options });
      }
    });
  });
};

export default cloudPaymentsPayment;
