import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useCallOnMount from './useCallOnMount';

const useDispatchOnMount = (callback, afterUpdate) => {
  const dispatch = useDispatch();
  const onMount = useCallback(() => dispatch(callback()), [callback, dispatch]);
  useCallOnMount(onMount, afterUpdate);
};

export default useDispatchOnMount;
