import { GET_COUNTRIES_LIST, GET_CURRENT_LOCATION } from './constants';

export const getCountriesList = () => (dispatch) => dispatch({
  type: GET_COUNTRIES_LIST,
  request: {
    endpoint: 'locations/countries'
  }
});

export const getCurrentLocation = () => (dispatch) => dispatch({
  type: GET_CURRENT_LOCATION,
  request: {
    method: 'GET',
    endpoint: 'locations/current'
  }
});
