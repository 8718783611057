import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from '../../Link';

import styles from './ButtonLink.module.scss';

const ButtonLink = ({
  theme,
  size,
  stretch,
  children,
  className,
  ...props
}) => (
  <Link
    className={cn({
      [styles[theme]]: styles[theme],
      [styles[size]]: styles[size],
      [styles.stretch]: stretch
    }, className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Link>
);

ButtonLink.propTypes = {
  theme: PropTypes.oneOf([
    'primary',
    'light',
    'dark'
  ]),
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg'
  ]),
  stretch: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

ButtonLink.defaultProps = {
  theme: 'primary',
  size: 'md',
  stretch: false,
  className: null,
  children: null
};

export default memo(ButtonLink);
