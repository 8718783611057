import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useRouter } from 'next/router';

import useBooleanState from '../../hooks/useBooleanState';
import ErrorBanner from './ErrorBanner';
import Footer from './Footer';
import Header from './Header';
import Hidden from '../UI/Hidden';
import Image from '../UI/Image';
import ModalManager from '../ModalManager';
import Sidebar from './Sidebar';
import SmallFooter from './SmallFooter';
import CookieConsentBanner from './CookieConsentBanner';
import Snow from './Snow';

import footerBgImg from './footerBg.jpg';
import styles from './Layout.module.scss';

const isHoliday = process.env.NEXT_PUBLIC_HOLIDAY_MODE === '1';

const Layout = ({
  centered,
  smallFooter,
  footerBg,
  footerSeoTitle,
  footerSeoText,
  children
}) => {
  const router = useRouter();

  const {
    isOpen: isSidebarOpen,
    toggle: onSidebarToggle,
    close: onSidebarClose
  } = useBooleanState();

  useEffect(() => {
    router.events.on('routeChangeComplete', onSidebarClose);

    return () => {
      router.events.off('routeChangeComplete', onSidebarClose);
    };
  }, [onSidebarClose, router.events]);

  return (
    <div className={styles.root}>
      {footerBg && (
        <div className={styles.footerBg}>
          <div className={styles.image}>
            <Image
              src={footerBgImg}
              alt="Footer background"
              layout="fill"
              objectFit="cover"
              sizes="100vw"
            />
          </div>
        </div>
      )}

      <div className={styles.header}>
        <Header
          isSidebarOpen={isSidebarOpen}
          onSidebarToggle={onSidebarToggle}
        />

        <ErrorBanner />
      </div>

      <div className={cn(styles.main, { [styles.centered]: centered })}>
        {children}
      </div>

      <div className={styles.footer}>
        {smallFooter && (
          <SmallFooter />
        )}

        {!smallFooter && (
          <Footer
            seoTitle={footerSeoTitle}
            seoText={footerSeoText}
          />
        )}
      </div>

      <Hidden xl>
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={onSidebarClose}
        />
      </Hidden>

      <ModalManager />
      <CookieConsentBanner />
      {isHoliday && (
        <Snow />
      )}
    </div>
  );
};
Layout.propTypes = {
  centered: PropTypes.bool,
  smallFooter: PropTypes.bool,
  footerBg: PropTypes.bool,
  footerSeoTitle: PropTypes.string,
  footerSeoText: PropTypes.string,
  children: PropTypes.node
};

Layout.defaultProps = {
  centered: false,
  smallFooter: false,
  footerBg: true,
  footerSeoTitle: null,
  footerSeoText: null,
  children: null
};

export default memo(Layout);
