import {
  memo, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { noop } from '../../utils';
import Modal from '../UI/Modal';
import InputSelectWithSearch from '../UI/InputSelectWithSearch';

import styles from './CountrySelectModal.module.scss';

const CountrySelectModal = ({
  open,
  listFetching,
  country,
  countryOptions,
  onMount,
  onSelect,
  onClose
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'CountrySelectModal' });

  const value = useMemo(() => {
    if (!country) {
      return undefined;
    }

    const option = countryOptions.find((item) => item.value.toLowerCase() === country.toLowerCase());
    return option?.value || undefined;
  }, [countryOptions, country]);

  const onChange = useCallback((item) => {
    onSelect(item.value);
    onClose();
  }, [onClose, onSelect]);

  useEffect(() => {
    if (open) {
      onMount();
    }
  }, [open, onMount]);

  return (
    <Modal
      open={open}
      className={styles.root}
      overlayClassName={styles.overlay}
      closeButtonClassName={styles.closeButton}
      onClose={onClose}
    >
      <InputSelectWithSearch
        fetching={listFetching}
        options={countryOptions}
        value={value}
        placeholder={t('placeholder')}
        onChange={onChange}
      />
    </Modal>
  );
};

CountrySelectModal.propTypes = {
  open: PropTypes.bool,
  listFetching: PropTypes.bool,
  country: PropTypes.string,
  countryOptions: PropTypes.array,
  onMount: PropTypes.func,
  onSelect: PropTypes.func,
  onClose: PropTypes.func
};

CountrySelectModal.defaultProps = {
  open: false,
  listFetching: false,
  country: undefined,
  countryOptions: [],
  onMount: noop,
  onSelect: noop,
  onClose: noop
};

export default memo(CountrySelectModal);
