import {
  memo,
  useCallback,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

import { noop } from '../../../utils';
import MainNav from '../MainNav';
import LangSwitch from '../LangSwitch';

import styles from './Sidebar.module.scss';

const TIMEOUT = 500;
const CLASS_NAMES = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  enterDone: styles.enterDone,
  exit: styles.exit,
  exitActive: styles.exitActive,
  exitDone: styles.exitDone
};

const Sidebar = ({
  isOpen,
  onClose
}) => {
  const ref = useRef(null);
  const sidebarRef = useRef(null);

  const onOutsideClick = useCallback(({ target }) => {
    if (!sidebarRef.current || sidebarRef.current.contains(target) || !ref.current.contains(target)) {
      return;
    }

    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', onOutsideClick, false);

      if (sidebarRef.current) {
        disableBodyScroll(sidebarRef.current);
      }
    } else if (sidebarRef.current) {
      enableBodyScroll(sidebarRef.current);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      window.removeEventListener('click', onOutsideClick, false);
    };
  }, [isOpen, onOutsideClick]);

  useEffect(() => () => {
    if (sidebarRef.current) {
      enableBodyScroll(sidebarRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, []);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={isOpen}
      nodeRef={ref}
      timeout={TIMEOUT}
      classNames={CLASS_NAMES}
    >
      <div
        ref={ref}
        className={styles.root}
      >
        <div
          ref={sidebarRef}
          className={styles.sidebar}
        >
          <div className={styles.mainNav}>
            <MainNav vertical />
          </div>

          <div className={styles.langSwitch}>
            <LangSwitch short />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

Sidebar.defaultProps = {
  isOpen: false,
  onClose: noop
};

export default memo(Sidebar);
