import ls from 'local-storage';
import i18nextConfig from '../../next-i18next.config';

export const noop = () => {};

export const noopPromise = () => Promise.resolve({});

export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const createArray = (length, iterator = () => null) => Array.from({ length }, iterator);

export const filterObjectKeys = (obj, keys = []) => keys.reduce((prev, key) => ({
  ...prev,
  [key]: obj ? obj[key] : null
}), {});

export const sortObjectKeys = (obj) => Object.keys(obj).sort().reduce((prev, cur) => ({
  ...prev,
  [cur]: obj[cur]
}), {});

export const shouldFactoryBindProps = (prevProps, nextProps, comparePropNames = []) => {
  if (prevProps === undefined || nextProps === undefined) {
    return true;
  }

  return comparePropNames.some((prop) => prevProps[prop] !== nextProps[prop]);
};

export const createDispatchPropsFactory = (createDispatchProps, comparePropNames = []) => {
  let prevOwnProps;
  let dispatchProps;

  return () => (dispatch, ownProps) => {
    const shouldBind = shouldFactoryBindProps(prevOwnProps, ownProps, comparePropNames);

    if (shouldBind) {
      dispatchProps = createDispatchProps(dispatch, ownProps);
      prevOwnProps = filterObjectKeys(ownProps, comparePropNames);
    }

    return dispatchProps;
  };
};

export const downloadUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;

  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const setLSLocale = (locale) => {
  if (locale && i18nextConfig.i18n.locales.includes(locale)) {
    ls.set('UPGRADER_I18NEXT_LANG', locale);
  }
};

export const getSavedLocale = () => {
  const locale = ls.get('UPGRADER_I18NEXT_LANG');

  if (locale && i18nextConfig.i18n.locales.includes(locale)) {
    return locale;
  }

  if (typeof window !== 'undefined' && window?.document?.documentElement?.getAttribute('lang')) {
    return window?.document?.documentElement?.getAttribute('lang');
  }

  return i18nextConfig.i18n.defaultLocale;
};

export const formatPrice = (amount, currency, locale = 'en') => {
  if (!currency || typeof amount !== 'number') {
    return '';
  }

  const result = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    currency
  }).format(amount);

  if (currency === 'EUR' || currency === 'USD') {
    return result.replace(/\s/g, '');
  }

  if (currency === 'RUB') {
    return result.replace(/\s₽$/gi, '₽');
  }

  return result;
};

export const getCurrencySymbol = (locale, currency) => (0).toLocaleString(
  locale,
  {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }
).replace(/\d/g, '').trim();

export const collectUtmTags = (query) => {
  if (!query) {
    return undefined;
  }

  const dict = ['source', 'medium', 'campaign', 'term', 'content'].reduce((prev, key) => {
    if (!query[`utm_${key}`]) {
      return prev;
    }

    return {
      ...prev,
      [key]: query[`utm_${key}`]
    };
  }, {});

  if (['source', 'medium', 'campaign'].filter((key) => !dict[key]).length) {
    return undefined;
  }

  return dict;
};

export const getItemLabelByPlan = (intervalDays) => {
  if (intervalDays === 0) {
    return 'Лицензионный платёж за пожизненный доступ к Upgrader';
  }

  return `Лицензионный платёж за доступ к Upgrader на ${intervalDays} дней`;
};
