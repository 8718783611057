import { memo } from 'react';
import Script from 'next/script';

const LIVECHAT_LICENSE_NUMBER = process.env.NEXT_PUBLIC_LIVECHAT_LICENSE_NUMBER;
const VK_PIXEL_ID_ALI = process.env.NEXT_PUBLIC_VK_PIXEL_ID_ALI;
const VK_PIXEL_ID_KARI = process.env.NEXT_PUBLIC_VK_PIXEL_ID_KARI;
const YANDEX_METRIKA_ID = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID;
const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
const GOOGLE_ADWORDS_ID = process.env.NEXT_PUBLIC_GOOGLE_ADWORDS_ID;
const GOOGLE_GTAG_ID = process.env.NEXT_PUBLIC_GOOGLE_GTAG_ID;
const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

const Scripts = () => (
  <>
    <Script
      strategy="beforeInteractive"
      src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"
    />

    {/* Xsolla Pay Station Widget */}
    <Script
      strategy="beforeInteractive"
      src="//cdn.xsolla.net/embed/paystation/1.2.7/widget.min.js"
    />

    {/* LiveChat */}
    {!!LIVECHAT_LICENSE_NUMBER && (
      <Script
        id="live_chat"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
        /* eslint-disable max-len */
          __html: `
          window.__lc = window.__lc || {};
          window.__lc.license = ${LIVECHAT_LICENSE_NUMBER};
          window.__lc.chat_between_groups = false;
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `
        /* eslint-enable max-len */
        }}
      />
    )}

    {/* VK pixel */}
    {!!VK_PIXEL_ID_ALI && (
      <Script
        id="vk_pixel_ali"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          /* eslint-disable max-len */
          __html: `
            !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("${VK_PIXEL_ID_ALI}"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
          `
          /* eslint-enable max-len */
        }}
      />
    )}

    {!!VK_PIXEL_ID_KARI && (
      <Script
        id="vk_pixel_kari"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          /* eslint-disable max-len */
          __html: `
            !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("${VK_PIXEL_ID_KARI}"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
          `
          /* eslint-enable max-len */
        }}
      />
    )}

    <Script
      id="twilio_segment"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        /* eslint-disable max-len */
        __html: `
        var analytics = window.analytics = window.analytics || [];
        if (!analytics.initialize)
            if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
            else {
                analytics.invoked = !0;
                analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
                analytics.factory = function(e) {
                    return function() {
                        var t = Array.prototype.slice.call(arguments);
                        t.unshift(e);
                        analytics.push(t);
                        return analytics
                    }
                };
                for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e];
                    analytics[key] = analytics.factory(key)
                }
                analytics.load = function(key, e) {
                    var t = document.createElement("script");
                    t.type = "text/javascript";
                    t.async = !0;
                    t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
                    var n = document.getElementsByTagName("script")[0];
                    n.parentNode.insertBefore(t, n);
                    analytics._loadOptions = e
                };
                analytics._writeKey = "wlCKZ6iA7vsSZmLHivkOdSUKbQo95EC9";;
                analytics.SNIPPET_VERSION = "4.15.3";
                analytics.load("wlCKZ6iA7vsSZmLHivkOdSUKbQo95EC9");
                analytics.page();
            }
        `
        /* eslint-enable max-len */
      }}
    />

    {/* Top.Mail.Ru counter / new VK Pixel */}
    <Script
      id="vk_pixel_new"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        /* eslint-disable max-len */
        __html: `
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({id: "3265888", type: "pageView", start: (new Date()).getTime()});
        (function (d, w, id) {
          if (d.getElementById(id)) return;
          var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
          ts.src = "https://top-fwz1.mail.ru/js/code.js";
          var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
          if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
        })(document, window, "tmr-code");
        `
        /* eslint-enable max-len */
      }}
    />

    {/* Yandex metrika */}
    {!!YANDEX_METRIKA_ID && (
      <Script
        id="yandex_metrika"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
        /* eslint-disable max-len */
          __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(${YANDEX_METRIKA_ID}, "init", {
            defer:true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            ecommerce:"dataLayer"
          });
        `
        /* eslint-enable max-len */
        }}
      />
    )}

    {/* Google analytics */}
    {!!GOOGLE_ANALYTICS_ID && (
      <>
        <Script
          id="google_analytics_gtag"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
        />

        <Script
          id="google_analytics_init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_ID}', {
                page_path: window.location.pathname,
                send_page_view: false
              });
              ${GOOGLE_ADWORDS_ID ? `gtag('config', '${GOOGLE_ADWORDS_ID}');` : ''}
            `
          }}
        />
      </>
    )}

    {/* Google tag */}
    <Script
      id="google_analytics_gtag"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_GTAG_ID}"`}
    />
    <Script
      id="google_analytics_gtag_init"
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date()); gtag('config', '${GOOGLE_GTAG_ID}');
        `
      }}
    />

    {/* Facebook pixel */}
    {!!FACEBOOK_PIXEL_ID && (
      <Script
        id="facebook_pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${FACEBOOK_PIXEL_ID}');
              fbq('track', 'PageView');
            `
        }}
      />
    )}
  </>
);

export default memo(Scripts);
