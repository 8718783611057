import { createActionRequestWithAuth } from '../utils';
import { POST_DEVICE_AUTH } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const authDevice = (uid) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: POST_DEVICE_AUTH,
  request: {
    method: 'POST',
    endpoint: `device/auth/session/${uid}`
  }
}));
