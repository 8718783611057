import { memo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styles from './ErrorBanner.module.scss';

const ErrorBanner = ({
  serverError,
  serverUnavailable,
  serverUnavailableDate
}) => {
  const { t, i18n } = useTranslation('layout', { keyPrefix: 'ErrorBanner' });

  if (serverError) {
    return (
      <div className={styles.root}>
        {t('serverErrorText')}
      </div>
    );
  }

  if (serverUnavailable) {
    if (serverUnavailableDate) {
      return (
        <div className={styles.root}>
          {t('serverUnavailableWithDateText')}
          {' '}
          {serverUnavailableDate.toLocaleDateString(i18n.language, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
          })}
        </div>
      );
    }

    return (
      <div className={styles.root}>
        {t('serverUnavailableText')}
      </div>
    );
  }

  return null;
};

ErrorBanner.propTypes = {
  serverError: PropTypes.bool,
  serverUnavailable: PropTypes.bool,
  serverUnavailableDate: PropTypes.instanceOf(Date)
};

ErrorBanner.defaultProps = {
  serverError: false,
  serverUnavailable: false,
  serverUnavailableDate: null
};

export default memo(ErrorBanner);
