import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ErrorMessage from '../ErrorMessage';

import styles from './Field.module.scss';

const Field = ({
  error,
  className,
  children
}) => {
  const errorResult = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }

    if (Array.isArray(error) && error.length) {
      return error[0];
    }

    return null;
  }, [error]);

  return (
    <div className={cn(styles.root, className)}>
      {children}

      {!!errorResult && (
        <div className={styles.error}>
          <ErrorMessage>
            {errorResult}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  error: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node
};

Field.defaultProps = {
  error: null,
  className: null,
  children: null
};

export default memo(Field);
