import { useRef, useEffect } from 'react';

const useCallOnMount = (callback, afterUpdate = false) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      if (afterUpdate) {
        requestAnimationFrame(callback);
      } else {
        callback();
      }
    }
  }, [afterUpdate, callback]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
};

export default useCallOnMount;
