import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Link from '../../Link';

import styles from './MainNav.module.scss';

const useNav = (hasSubscription) => {
  const { t } = useTranslation('layout', { keyPrefix: 'Nav' });
  const nav = useMemo(() => [
    {
      key: 'pricing',
      href: hasSubscription ? '/profile/subscription/' : '/pricing/',
      title: t('pricing')
    },
    {
      key: 'home',
      href: '/',
      title: t('home')
    },
    {
      key: 'games',
      href: '/games/',
      title: t('games')
    },
    // {
    //   key: 'system',
    //   href: '/system/',
    //   title: t('system')
    // }
    // {
    //   key: 'contacts',
    //   href: '/contacts/',
    //   title: t('contacts')
    // },
    // {
    //   key: 'subscription',
    //   href: '/subscription/',
    //   title: t('subscription')
    // },
    {
      key: 'blog',
      href: '/blog/',
      title: t('blog')
    }
    // {
    //   key: 'support',
    //   href: '/support/',
    //   title: t('support')
    // }
  ], [hasSubscription, t]);

  return nav;
};

const MainNav = ({
  vertical,
  hasSubscription
}) => {
  const items = useNav(hasSubscription);

  return (
    <div
      className={cn({
        [styles.horizontal]: !vertical,
        [styles.vertical]: vertical
      })}
    >
      {items.map(({
        key,
        href,
        title
      }) => (
        <div
          key={key}
          className={styles.item}
        >
          <Link
            href={href}
            simpleStyle
            className={styles.link}
            activeClassName={styles.active}
          >
            {title}
          </Link>
        </div>
      ))}
    </div>
  );
};

MainNav.propTypes = {
  vertical: PropTypes.bool,
  hasSubscription: PropTypes.bool
};

MainNav.defaultProps = {
  vertical: false,
  hasSubscription: false
};

export default memo(MainNav);
