import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon, { ALL_ICONS_LIST } from '../Icon';

import styles from './IconButton.module.scss';

const ICON_SIZE_MAP = {
  md: 14,
  sm: 8
};

const IconButton = ({
  icon,
  size,
  theme,
  iconSize,
  className,
  ...props
}) => {
  const resultIconSize = useMemo(() => iconSize || ICON_SIZE_MAP[size], [iconSize, size]);

  return (
    <button
      type="button"
      className={cn(styles.root, {
        [styles[theme]]: styles[theme],
        [styles[size]]: styles[size]
      }, className)}
      {...props}
    >
      <Icon
        name={icon}
        size={resultIconSize}
      />
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.oneOf(ALL_ICONS_LIST).isRequired,
  theme: PropTypes.oneOf([
    'light',
    'dark'
  ]),
  size: PropTypes.oneOf([
    'sm',
    'md'
  ]),
  iconSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string
};

IconButton.defaultProps = {
  size: 'md',
  theme: 'light',
  iconSize: null,
  className: null
};

export default memo(IconButton);
