import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';
import ConfirmCountry from '../ConfirmCountry';
import Container from '../../UI/Container';
import Controls from '../Controls';
import Hidden from '../../UI/Hidden';
import LangSwitch from '../LangSwitch';
import LogoLink from '../LogoLink';
import MainNav from '../MainNav';
import SidebarToggle from '../SidebarToggle';
import SignInLink from '../SignInLink';
import UserBar from '../UserBar';
import Visible from '../../UI/Visible';

import styles from './Header.module.scss';

const Header = ({
  userReady,
  authorized,
  showConfirmCountry,
  isSidebarOpen,
  onSidebarToggle
}) => (
  <div className={styles.root}>
    <Container>
      <div className={styles.inner}>
        {userReady && !authorized && (
          <Hidden
            xl
            className={styles.signInLink}
          >
            <SignInLink />
          </Hidden>
        )}

        <div className={styles.logoLink}>
          <LogoLink />
        </div>

        <Visible xl>
          <MainNav />
        </Visible>

        {!userReady && (
          <div className={styles.userBarHolder} />
        )}

        {userReady && (
          <div className={cn(styles.controlsWrapper, { [styles.authorized]: authorized })}>
            <div className={styles.controls}>
              <Controls authorized={authorized} />
            </div>

            <div className={styles.langSwitch}>
              <LangSwitch
                short
              />
            </div>

            {authorized && (
              <div className={styles.userBar}>
                <UserBar />
              </div>
            )}
          </div>
        )}

        <Hidden
          xl
          className={styles.sidebarToggle}
        >
          <SidebarToggle
            cross={isSidebarOpen}
            onClick={onSidebarToggle}
          />
        </Hidden>

        {showConfirmCountry && (
          <div className={styles.confirmCountry}>
            <ConfirmCountry />
          </div>
        )}
      </div>
    </Container>
  </div>
);

Header.propTypes = {
  userReady: PropTypes.bool,
  authorized: PropTypes.bool,
  showConfirmCountry: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  onSidebarToggle: PropTypes.func
};

Header.defaultProps = {
  userReady: false,
  authorized: false,
  showConfirmCountry: false,
  isSidebarOpen: false,
  onSidebarToggle: noop
};

export default memo(Header);
