import Cookies from 'js-cookie';
import ls from 'local-storage';

import { isSuccessAction, createActionRequestWithAuth } from '../utils';
import {
  CHECK_EMAIL,
  REGISTRATION,
  LOGIN,
  SET_AUTHORIZED,
  RESEND_EMAIL_CONFIRMATION_CODE,
  CONFIRM_EMAIL,
  OAUTH2_LOGIN,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_RESEND,
  RESET_PASSWORD,
  LOGOUT
} from './constants';
import { getProfile, changeCountry, resetProfile } from '../profile/actions';
import { clearPlansList, getPlansList } from '../plansList/actions';
import {
  idSelector,
  locationSelector,
  emailSelector,
  subscriptionActiveSelector,
  subscriptionAutoRenewalSelector, subscriptionStatusSelector
} from '../profile/selectors';
import { setConfirmCountry } from '../app/actions';
import { authDevice } from '../device/actions';
import { getCurrentLocation } from '../locations/actions';
import { addSuccessNotification } from '../notifications/actions';
import { gaSendData, gaSendEvent } from '../../utils/ga';
import { ymSendData } from '../../utils/ym';
import { sendCollectedData } from '../analytics/actions';
import { FBLead, FBCompleteRegistration } from '../../utils/fb';

export const setAuthorized = (authorized) => ({
  type: SET_AUTHORIZED,
  authorized
});

export const onAuthorized = (token, expiresIn) => async(dispatch, getState) => {
  if (token) {
    const options = expiresIn ? {
      expires: new Date(new Date().setSeconds(new Date().getSeconds() + expiresIn))
    } : null;
    Cookies.set('access_token', token, options);
  }

  dispatch(clearPlansList());

  await Promise.all([
    dispatch(getProfile()),
    dispatch(getPlansList())
  ]);

  const state = getState();
  const userId = idSelector(state);
  const location = locationSelector(state);
  const email = emailSelector(state);
  const sessionUid = Cookies.get('auth_session_uid');

  if (sessionUid) {
    dispatch(authDevice(sessionUid)).then(() => {
      Cookies.remove('auth_session_uid');
    });
  }

  if (typeof userId === 'number') {
    gaSendData({ user_id: userId });
    ymSendData('setUserID', userId);

    window?.analytics?.identify(userId, {
      email,
      subscription_active: subscriptionActiveSelector(state),
      subscription_auto_renewal: subscriptionAutoRenewalSelector(state),
      subscription_status: subscriptionStatusSelector(state)
    });
  }

  if (location && !location.isCountryConfirmed) {
    const country = Cookies.get('Country');

    if (country) {
      dispatch(changeCountry(country));
    } else {
      dispatch(getCurrentLocation()).then(() => {
        dispatch(setConfirmCountry(true));
      });
    }
  }

  dispatch(sendCollectedData());
};

export const onUnauthorized = () => (dispatch) => {
  Cookies.remove('access_token');

  dispatch(clearPlansList());
  dispatch(setAuthorized(false));
  dispatch(resetProfile());
  dispatch(getPlansList());
};

export const checkEmail = ({ email }) => (dispatch) => dispatch({
  type: CHECK_EMAIL,
  request: {
    endpoint: 'users/emails/check',
    queryParams: {
      email
    }
  }
});

export const registration = ({ email, password }, recaptchaToken) => async(dispatch) => {
  const action = await dispatch({
    type: REGISTRATION,
    request: {
      method: 'POST',
      endpoint: 'users/emails/password/signup',
      data: {
        email,
        password
      },
      headers: {
        'X-RECAPTCHA-TOKEN': recaptchaToken
      }
    }
  });

  if (isSuccessAction(action)) {
    const {
      accessToken,
      expiresIn,
      lastVerificationMailSent
    } = action.request.response.data;

    if (lastVerificationMailSent) {
      ls.set('last_verification_mail_sent', lastVerificationMailSent);
      ymSendData('reachGoal', 'sign-up_verification_code_sent');
    }

    if (accessToken) {
      ymSendData('reachGoal', 'sign-up-complete');
      gaSendEvent('sign_up', { method: 'email' });
      dispatch(onAuthorized(accessToken, expiresIn));
      FBLead();
      FBCompleteRegistration();
    }
  }

  return action;
};

export const login = ({ email, password, code }, recaptchaToken) => async(dispatch) => {
  const action = await dispatch({
    type: LOGIN,
    request: {
      method: 'POST',
      endpoint: 'users/emails/password/login',
      data: {
        email,
        password,
        verificationCode: code
      },
      headers: {
        'X-RECAPTCHA-TOKEN': recaptchaToken
      }
    }
  });

  if (isSuccessAction(action)) {
    const {
      accessToken,
      expiresIn,
      lastVerificationMailSent
    } = action.request.response.data;

    if (lastVerificationMailSent) {
      ls.set('last_verification_mail_sent', lastVerificationMailSent);
    }

    if (accessToken) {
      gaSendEvent('login', { method: 'email' });
      gaSendEvent('login_on');
      dispatch(onAuthorized(accessToken, expiresIn));
    }
  }

  return action;
};

export const resendEmailConfirmationCode = (email) => async(dispatch) => {
  const action = await dispatch({
    type: RESEND_EMAIL_CONFIRMATION_CODE,
    request: {
      method: 'POST',
      endpoint: 'users/emails/verification/send',
      data: {
        email
      }
    }
  });

  if (isSuccessAction(action)) {
    const {
      message,
      lastVerificationMailSent
    } = action.request.response.data;

    if (message) {
      addSuccessNotification(message);
    }

    if (lastVerificationMailSent) {
      ls.set('last_verification_mail_sent', lastVerificationMailSent);
    }
  }

  return action;
};

export const confirmEmail = ({ email, code }) => async(dispatch) => {
  const action = await dispatch({
    type: CONFIRM_EMAIL,
    request: {
      method: 'POST',
      endpoint: 'users/emails/verification/confirm',
      data: {
        email,
        verificationCode: code
      }
    }
  });

  if (isSuccessAction(action)) {
    const { accessToken, expiresIn } = action.request.response.data;

    if (accessToken) {
      gaSendEvent('login', { method: 'email' });
      dispatch(onAuthorized(accessToken, expiresIn));
    }
  }

  return action;
};

export const oauth2Login = (provider, code) => async(dispatch) => {
  const action = await dispatch({
    type: OAUTH2_LOGIN,
    request: {
      method: 'POST',
      endpoint: `users/auth/${provider}`,
      data: { code }
    }
  });

  if (isSuccessAction(action)) {
    const { accessToken, expiresIn } = action.request.response.data;
    gaSendEvent('login', { method: provider });
    dispatch(onAuthorized(accessToken, expiresIn));
  }

  return action;
};

export const requestPasswordReset = ({ email }, recaptchaToken) => async(dispatch) => {
  const action = await dispatch({
    type: REQUEST_PASSWORD_RESET,
    request: {
      method: 'POST',
      endpoint: 'users/emails/password/reset',
      data: {
        email
      },
      headers: {
        'X-RECAPTCHA-TOKEN': recaptchaToken
      }
    }
  });

  if (isSuccessAction(action) && action.request.response.data.message) {
    const { message, sentAt } = action.request.response.data;

    ls.set('last_reset_sent_at', sentAt || new Date().toISOString());

    if (message) {
      dispatch(addSuccessNotification(message));
    }
  }

  return action;
};

export const requestPasswordResetResend = (token) => async(dispatch) => {
  const action = await dispatch({
    type: REQUEST_PASSWORD_RESET_RESEND,
    request: {
      method: 'POST',
      endpoint: 'users/emails/password/reset/resend',
      data: {
        token
      }
    }
  });

  if (isSuccessAction(action) && action.request.response.data.message) {
    const { message, sentAt } = action.request.response.data;

    ls.set('last_reset_sent_at', sentAt || new Date().toISOString());

    if (message) {
      dispatch(addSuccessNotification(message));
    }
  }

  return action;
};

export const resetPassword = ({ code, token, newPassword }) => async(dispatch) => {
  const action = await dispatch({
    type: RESET_PASSWORD,
    request: {
      method: 'PUT',
      endpoint: 'users/password/reset',
      queryParams: {
        code,
        token,
        new_password: newPassword
      }
    }
  });

  if (isSuccessAction(action)) {
    const { accessToken, expiresIn } = action.request.response.data;
    gaSendEvent('login', { method: 'email' });
    dispatch(onAuthorized(accessToken, expiresIn));
  }

  return action;
};

export const logout = () => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: LOGOUT,
    request: {
      endpoint: 'users/auth/logout'
    }
  }));

  dispatch(onUnauthorized());

  return action;
};
