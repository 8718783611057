import {
  SET_MODAL,
  SET_CONFIRM_COUNTRY,
  SET_SERVER_UNAVAILABLE,
  SET_SERVER_ERROR
} from './constants';

export const setModal = (name, data = null) => ({
  type: SET_MODAL,
  name,
  data
});

export const setConfirmCountry = (confirmCountry) => ({
  type: SET_CONFIRM_COUNTRY,
  confirmCountry
});

export const setServerUnavailable = (date) => ({
  type: SET_SERVER_UNAVAILABLE,
  date
});

export const setServerError = () => ({
  type: SET_SERVER_ERROR
});
