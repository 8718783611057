import {
  createReducer,
  createRequestReducer
} from '../utils';
import {
  GET_COUNTRIES_LIST,
  GET_CURRENT_LOCATION
} from './constants';

const initialData = {
  fetching: false,
  countries: null,
  current: null
};

const locations = createReducer(initialData, {
  [GET_COUNTRIES_LIST]: (state, action) => createRequestReducer(state, action, {
    SEND: () => ({
      ...state,
      fetching: true
    }),

    SUCCESS: () => ({
      ...state,
      fetching: false,
      countries: action.request.response.data || null
    }),

    FAIL: () => ({
      ...state,
      fetching: false
    })
  }),

  [GET_CURRENT_LOCATION]: (state, action) => createRequestReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      current: action.request.response.data || null
    })
  })
});

export default locations;
