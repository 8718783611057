import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import { GET_SUBSCRIPTIONS } from './constants';

const subscriptions = createReducer(defaultFetchDataState, {
  [GET_SUBSCRIPTIONS]: defaultFetchReducer
});

export default subscriptions;
