import { createReducer } from '../utils';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './constants';

const initialState = {
  items: []
};

const notifications = createReducer(initialState, {
  [ADD_NOTIFICATION]: (state, { data }) => ({
    ...state,
    items: [
      ...state.items,
      {
        ...data,
        id: Math.max(-1, ...state.items.map(({ id }) => id)) + 1
      }
    ]
  }),

  [REMOVE_NOTIFICATION]: (state, { id }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== id)
  })
});

export default notifications;
