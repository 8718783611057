import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../../Link';
import Icon from '../../UI/Icon';

import styles from './SignInLink.module.scss';

const SignInLink = () => {
  const { t } = useTranslation('layout', { keyPrefix: 'UnauthorizedControls' });

  return (
    <Link
      href="/sign-in/"
      aria-label={t('UnauthorizedControls.signInButtonText')}
      className={styles.root}
    >
      <Icon
        name="User"
        size="18"
      />
    </Link>
  );
};

export default memo(SignInLink);
