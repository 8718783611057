import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { confirmCountrySelector } from '../../../redux/app/selectors';
import {
  authorizedSelector,
  readySelector as authReadySelector
} from '../../../redux/auth/selectors';
import { readySelector as profileReadySelector } from '../../../redux/profile/selectors';

import Header from './Header';

const userReadySelector = createSelector(
  authReadySelector,
  profileReadySelector,
  authorizedSelector,
  (authReady, profileReady, authorized) => {
    if (!authReady) {
      return false;
    }

    if (!authorized) {
      return true;
    }

    return profileReady;
  }
);

const mapStateToProps = createStructuredSelector({
  userReady: userReadySelector,
  authorized: authorizedSelector,
  showConfirmCountry: confirmCountrySelector
});

export default connect(mapStateToProps)(Header);
