import {
  memo,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Downshift from 'downshift';
import cn from 'classnames';

import { noop } from '../../../utils';
import Icon from '../Icon';
import Hidden from '../Hidden';
import Spinner from '../Spinner';

import styles from './InputSelectWithSearch.module.scss';

const Legend = memo(() => {
  const { t } = useTranslation('common', { keyPrefix: 'InputSelectWithSearch' });

  return (
    <Hidden
      xs
      sm
    >
      <div className={styles.legend}>
        <div className={styles.navHint}>
          <Icon
            name="ArrowUp"
            size="11"
          />

          <Icon
            name="ArrowDown"
            size="11"
          />

          <span>
            {t('navigationHintText')}
          </span>
        </div>

        <div className={styles.selectHint}>
          <Icon
            name="ArrowDownLeft"
            size="12"
          />

          <span>
            {t('selectHintText')}
          </span>
        </div>
      </div>
    </Hidden>
  );
});

const MenuItem = ({
  item,
  index,
  highlighted,
  selected,
  getItemProps
}) => {
  const itemProps = getItemProps({
    key: item.value,
    index,
    item,
    className: cn(styles.item, { [styles.highlighted]: highlighted })
  });

  return (
    <div {...itemProps}>
      {item.label}

      {selected && (
        <Icon
          name="Check"
          size="12"
          className={styles.check}
        />
      )}
    </div>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  index: PropTypes.number.isRequired,
  highlighted: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  getItemProps: PropTypes.func.isRequired
};

const Menu = ({
  loading,
  inputValue,
  highlightedIndex,
  value,
  options,
  getMenuProps,
  getItemProps
}) => {
  const menuProps = getMenuProps({ className: styles.menu });
  const list = useMemo(() => {
    const search = inputValue.trim().toLowerCase();
    let result = options.sort((a, b) => {
      if (a.value === value) {
        return -1;
      }

      if (b.value === value) {
        return 1;
      }

      return `${a.label}`.toLowerCase().indexOf(search) - `${b.label}`.toLowerCase().indexOf(search);
    });

    if (search) {
      result = result.filter((item) => {
        const val = String(item.value).toLowerCase();
        const label = String(item.label).toLowerCase();

        return val.includes(search) || label.includes(search);
      });
    }

    return result;
  }, [inputValue, options, value]);

  return (
    <div {...menuProps}>
      <div className={styles.list}>
        {loading && (
          <div className={styles.loading}>
            <Spinner
              theme="white"
            />
          </div>
        )}

        {list.map((item, index) => (
          <MenuItem
            key={item.value}
            item={item}
            index={index}
            highlighted={highlightedIndex === index}
            selected={value === item.value}
            getItemProps={getItemProps}
          />
        ))}
      </div>

      <Legend />
    </div>
  );
};

Menu.propTypes = {
  loading: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  highlightedIndex: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })),
  getMenuProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired
};
Menu.defaultProps = {
  value: undefined,
  options: [],
  highlightedIndex: null
};

const SearchInput = ({
  placeholder,
  getRootProps,
  getInputProps
}) => {
  const onFocus = useCallback((event) => {
    event?.target?.select();
  }, []);
  const rootProps = getRootProps({}, { suppressRefError: true });
  const inputProps = getInputProps({
    autoFocus: true,
    placeholder,
    className: styles.input,
    onFocus
  });

  return (
    <div {...rootProps}>
      <Icon
        name="Magnifier"
        size="18"
        className={styles.icon}
      />

      <input {...inputProps} />
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired
};
SearchInput.defaultProps = {
  placeholder: undefined
};

const InputSelectWithSearch = ({
  fetching,
  options,
  value,
  className,
  placeholder,
  onChange
}) => {
  const itemToString = useCallback((item) => item?.label || '', []);
  const onChangeProxy = useCallback((item) => {
    onChange(item);
  }, [onChange]);

  return (
    <Downshift
      initialIsOpen
      itemToString={itemToString}
      onChange={onChangeProxy}
    >
      {({
        inputValue,
        highlightedIndex,
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps
      }) => (
        <div className={cn(styles.root, className)}>
          <SearchInput
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            placeholder={placeholder}
          />

          <Menu
            loading={fetching}
            inputValue={inputValue}
            highlightedIndex={highlightedIndex}
            value={value}
            options={options}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
          />
        </div>
      )}
    </Downshift>
  );
};

InputSelectWithSearch.propTypes = {
  fetching: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
};

InputSelectWithSearch.defaultProps = {
  fetching: false,
  options: [],
  value: undefined,
  placeholder: undefined,
  className: undefined,
  onChange: noop
};

export default memo(InputSelectWithSearch);
