import {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useBooleanState from '../../../hooks/useBooleanState';
import Heading from '../../UI/Heading';
import Icon from '../../UI/Icon';
import Hidden from '../../UI/Hidden';
import TransitionExpand from '../../UI/TransitionExpand';

import styles from './SeoText.module.scss';

const calcNumberOfLines = (target) => {
  const style = window.getComputedStyle(target, null);
  const height = parseInt(style.getPropertyValue('height'), 10);
  const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);

  return height / lineHeight;
};

const SeoText = ({
  title,
  text
}) => {
  const textRef = useRef(null);
  const { t } = useTranslation('layout', { keyPrefix: 'SeoText' });

  const [disableToggle, setDisableToggle] = useState(false);
  const { isOpen, close, toggle } = useBooleanState(true);

  const toggleButtonText = useMemo(() => (
    isOpen ? t('collapseButtomText') : t('expandButtonText')
  ), [isOpen, t]);

  useEffect(() => {
    if (calcNumberOfLines(textRef.current) < 3) {
      setDisableToggle(true);
    } else {
      close();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <Heading
          level="4"
          tag="h2"
          responsive
        >
          {title || t('title')}
        </Heading>

        {!disableToggle && (
          <Hidden
            xs
            sm
            md
            className={styles.topToggle}
          >
            <button
              type="button"
              className={cn(styles.toggle, {
                [styles.open]: isOpen
              })}
              onClick={toggle}
            >
              {toggleButtonText}

              <Icon
                name="Chevron"
                size="10"
                className={styles.chevron}
              />
            </button>
          </Hidden>
        )}
      </div>

      <TransitionExpand
        open={disableToggle || isOpen}
        mount={false}
        minHeight={40}
        duration={250}
        className={cn(styles.collapse, {
          [styles.closed]: !disableToggle && !isOpen
        })}
      >
        <div
          ref={textRef}
          className={styles.text}
        >
          {text || t('text')}
        </div>
      </TransitionExpand>

      {!disableToggle && (
        <Hidden
          lg
          xl
          className={styles.bottomToggle}
        >
          <button
            type="button"
            className={cn(styles.toggle, {
              [styles.open]: isOpen
            })}
            onClick={toggle}
          >
            {toggleButtonText}

            <Icon
              name="Chevron"
              size="10"
              className={styles.chevron}
            />
          </button>
        </Hidden>
      )}
    </div>
  );
};

SeoText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

SeoText.defaultProps = {
  title: null,
  text: null
};

export default memo(SeoText);
