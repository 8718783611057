import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { noop } from '../../../utils';
import Link from '../../Link';
import Menu, { MenuHandle, MenuItemButton, MenuItemLink } from '../../UI/Menu';
import UserPreview from '../../UserPreview';

import styles from './UserBar.module.scss';

const UserBar = ({
  ready,
  onLogout
}) => {
  const { t } = useTranslation('layout', { keyPrefix: 'UserBar' });

  const onLogoutButtonClick = useCallback(() => {
    onLogout();
  }, [onLogout]);

  if (!ready) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Link
        href="/profile/"
        className={styles.userPreview}
      >
        <UserPreview size="sm" />
      </Link>

      <div className={styles.control}>
        <Menu
          className={styles.menu}
          menuClassName={styles.menuDropdown}
          handle={(
            <MenuHandle className={styles.menuHandle} />
          )}
          menu={(
            <>
              <MenuItemLink
                href="/profile/"
              >
                {t('profileLinkTitle')}
              </MenuItemLink>

              <MenuItemLink href="/profile/subscription/">
                {t('subscriptionLinkTitle')}
              </MenuItemLink>

              <MenuItemButton onClick={onLogoutButtonClick}>
                {t('logoutButtonTitle')}
              </MenuItemButton>
            </>
          )}
        />
      </div>
    </div>
  );
};

UserBar.propTypes = {
  ready: PropTypes.bool,
  onLogout: PropTypes.func
};

UserBar.defaultProps = {
  ready: false,
  onLogout: noop
};

export default memo(UserBar);
