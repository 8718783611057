import splitbee from '@splitbee/web';

const SPLITBEE_TOKEN = process.env.NEXT_PUBLIC_SPLITBEE_TOKEN;

// eslint-disable-next-line import/prefer-default-export
export const splitbeeInit = () => {
  if (SPLITBEE_TOKEN) {
    splitbee.init({
      token: SPLITBEE_TOKEN
    });
  }
};
