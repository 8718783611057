import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import { GET_PROFILE, RESET_STATE } from './constants';

const profile = createReducer(defaultFetchDataState, {
  [GET_PROFILE]: defaultFetchReducer,

  [RESET_STATE]: () => ({
    ...defaultFetchDataState
  })
});

export default profile;
