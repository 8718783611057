import PropTypes from 'prop-types';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import nextStore from '../redux';
import { onAuthorized, setAuthorized } from '../redux/auth/actions';
import { getPlansList } from '../redux/plansList/actions';
import { getCurrentLocation } from '../redux/locations/actions';
import { setConfirmCountry } from '../redux/app/actions';

import { setLSLocale } from '../utils';
import { ymSendPageView } from '../utils/ym';
import { gaSendPageView } from '../utils/ga';
import { FBPageView } from '../utils/fb';
import { splitbeeInit } from '../utils/splitbee';
import configureValidation from '../configureValidation';
import useCallOnMount from '../hooks/useCallOnMount';
import useDispatchOnMount from '../hooks/useDispatchOnMount';
import useCallOnRouteChange from '../hooks/useCallOnRouteChange';
import useCollectAnalytics from '../hooks/useCollectAnalytics';

import Layout from '../components/Layout';
import Scripts from '../components/Scripts';
import Notifications from '../components/Notifications';

import '../styles/index.scss';

const DefaultLayout = (page) => (
  <Layout>
    {page}
  </Layout>
);
const getInitialData = () => (dispatch) => {
  const token = Cookies.get('access_token');
  const country = Cookies.get('Country');

  if (token) {
    dispatch(setAuthorized(true));
    dispatch(onAuthorized());
  } else {
    dispatch(setAuthorized(false));
    dispatch(getPlansList());

    if (!country) {
      dispatch(getCurrentLocation()).then(() => {
        dispatch(setConfirmCountry(true));
      });
    }
  }
};
const onRouteChange = (url) => {
  ymSendPageView(url);
  gaSendPageView(url);
  FBPageView();
};

const App = ({ Component, pageProps }) => {
  const { t, i18n } = useTranslation();
  const getLayout = Component.getLayout || DefaultLayout;

  useCallOnMount(() => {
    setLSLocale(i18n.language);
    configureValidation(t);
    splitbeeInit();
  });

  useCollectAnalytics();
  useDispatchOnMount(getInitialData);
  useCallOnRouteChange(onRouteChange);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
      </Head>

      {getLayout((
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...pageProps}
        />
      ))}

      <Notifications />

      <Scripts />
    </>
  );
};

App.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  pageProps: PropTypes.object.isRequired
};

export default nextStore.withRedux(appWithTranslation(App));
