import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing()
  ],
  blacklistUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extensions:\/\//i
  ]
});
