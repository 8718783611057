import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';

import languageDetector from '../../../i18n/languageDetector';
import Icon from '../../UI/Icon';

import styles from './LangLink.module.scss';

const LangLink = ({
  locale,
  href,
  title,
  active
}) => {
  const {
    asPath,
    pathname,
    query
  } = useRouter();

  const resultHref = useMemo(() => {
    if (!locale) {
      return href || asPath;
    }

    if (href) {
      return `/${locale}${href}`;
    }

    return Object.keys(query).reduce((prev, key) => {
      if (key === 'locale') {
        return prev.replace(`[${key}]`, locale);
      }

      return prev.replace(`[${key}]`, query[key]);
    }, pathname);
  }, [
    asPath,
    href,
    locale,
    pathname,
    query
  ]);

  const onClick = useCallback(() => {
    languageDetector.cache(locale);
  }, [locale]);
  const onKeyPress = useCallback((event) => {
    if (event.code === 'Enter') {
      languageDetector.cache(locale);
    }
  }, [locale]);

  return (
    <Link href={resultHref}>
      <a // eslint-disable-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
        className={cn(styles.root, { [styles.active]: active })}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        {title || locale}

        {active && (
          <Icon
            name="Check"
            size="10"
            className={styles.icon}
          />
        )}
      </a>
    </Link>
  );
};

LangLink.propTypes = {
  locale: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool
};

LangLink.defaultProps = {
  locale: null,
  href: null,
  title: null,
  active: false
};

export default memo(LangLink);
