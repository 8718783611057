import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../utils';
import Modal from '../UI/Modal';
import CancellationPolicy from './CancellationPolicy';
import HowCancelSubscription from './HowCancelSubscription';
import IframeCheckout from './IframeCheckout';
import NotWindowsDownloadDialog from './NotWindowsDownloadDialog';
import PaymentFailed from './PaymentFailed';

const MODALS = {
  CancellationPolicy,
  HowCancelSubscription,
  IframeCheckout,
  NotWindowsDownloadDialog,
  PaymentFailed
};
const MODALS_KEYS = Object.keys(MODALS);
const MODAL_WIDTH = {
  CancellationPolicy: 720,
  HowCancelSubscription: 720,
  IframeCheckout: 720
};
const DefaultContent = () => null;

const ModalManager = ({
  modal,
  data,
  onClose
}) => {
  const open = useMemo(() => MODALS_KEYS.includes(modal), [modal]);
  const Content = useMemo(() => MODALS[modal] || DefaultContent, [modal]);
  const style = useMemo(() => (
    MODAL_WIDTH[modal] ? { content: { width: MODAL_WIDTH[modal] } } : {}
  ), [modal]);

  return (
    <Modal
      open={open}
      style={style}
      onClose={onClose}
    >
      <Content
        data={data}
        onClose={onClose}
      />
    </Modal>
  );
};

ModalManager.propTypes = {
  modal: PropTypes.oneOf(MODALS_KEYS),
  data: PropTypes.object,
  onClose: PropTypes.func
};

ModalManager.defaultProps = {
  modal: null,
  data: {},
  onClose: noop
};

export default memo(ModalManager);
