import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Cookies from 'js-cookie';

import { isSuccessAction } from '../../../redux/utils';
import {
  currentCountryNameSelector,
  currentCountryCodeSelector,
  countryOptionsSelector
} from '../../../redux/locations/selectors';
import { authorizedSelector } from '../../../redux/auth/selectors';

import { setConfirmCountry } from '../../../redux/app/actions';
import { getPlansList } from '../../../redux/plansList/actions';
import { changeCountry } from '../../../redux/profile/actions';
import { addSuccessNotification } from '../../../redux/notifications/actions';

import ConfirmCountry from './ConfirmCountry';

const mapStateToProps = createStructuredSelector({
  countryCode: currentCountryCodeSelector,
  countryName: currentCountryNameSelector,
  countryList: countryOptionsSelector
});

const mapDispatchToProps = {
  onConfirm: (code) => async(dispatch, getState) => {
    if (!code) {
      return;
    }

    Cookies.set('Country', code);

    const authorized = authorizedSelector(getState());

    if (authorized) {
      const action = await dispatch(changeCountry(code));

      if (isSuccessAction(action) && action.request.response?.data?.message) {
        dispatch(addSuccessNotification(action.request.response.data.message));
      }
    }
  },
  onChange: (code) => async(dispatch, getState) => {
    if (!code) {
      return;
    }

    Cookies.set('Country', code);

    const authorized = authorizedSelector(getState());

    if (authorized) {
      const action = await dispatch(changeCountry(code));

      if (isSuccessAction(action) && action.request.response?.data?.message) {
        dispatch(addSuccessNotification(action.request.response.data.message));
      }
    } else {
      dispatch(getPlansList());
    }
  },
  onClose: () => (dispatch) => {
    dispatch(setConfirmCountry(false));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCountry);
