import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Container.module.scss';

const Container = ({ className, children }) => (
  <div className={cn(styles.root, className)}>
    {children}
  </div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

Container.defaultProps = {
  className: null,
  children: null
};

export default memo(Container);
