import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Spinner from '../Spinner';

import styles from './Form.module.scss';

const Form = ({
  processing,
  className,
  children,
  ...props
}) => (
  <form
    noValidate
    className={cn(styles.root, {
      [styles.processing]: processing
    }, className)}
    {...props}
  >
    {children}

    {processing && (
      <div className={styles.loading}>
        <Spinner
          theme="white"
        />
      </div>
    )}
  </form>
);

Form.propTypes = {
  processing: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

Form.defaultProps = {
  processing: false,
  className: null,
  children: null
};

export default memo(Form);
