import { createReducer } from '../utils';
import {
  SET_MODAL,
  SET_CONFIRM_COUNTRY,
  SET_SERVER_UNAVAILABLE,
  SET_SERVER_ERROR
} from './constants';

const initialState = {
  modal: null,
  modalData: null,
  serverError: false,
  serverUnavailable: false,
  serverUnavailableDate: null
};

const getDate = (value) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'number' || /^\d+$/.test(value)) {
    return new Date(new Date().getTime() + parseInt(value, 10) * 1000);
  }

  return new Date(value);
};

const app = createReducer(initialState, {
  [SET_MODAL]: (state, { name, data }) => ({
    ...state,
    modal: name,
    modalData: data
  }),

  [SET_CONFIRM_COUNTRY]: (state, { confirmCountry }) => ({
    ...state,
    confirmCountry
  }),

  [SET_SERVER_UNAVAILABLE]: (state, { date }) => ({
    ...state,
    serverUnavailable: true,
    serverUnavailableDate: getDate(date)
  }),

  [SET_SERVER_ERROR]: (state) => ({
    ...state,
    serverError: true
  })
});

export default app;
