import { pricesUidsSelector } from '../plansList/selectors';
import {
  GET_COUPON,
  COUPON_CLEAR
} from './constants';

export const getCoupon = (name) => (dispatch, getState) => {
  const pricesUids = pricesUidsSelector(getState());

  return dispatch({
    type: GET_COUPON,
    name,
    uids: pricesUids,
    request: {
      endpoint: `coupons/${name}`,
      disableErrorNotification: true
    }
  });
};

export const clearCoupon = () => ({
  type: COUPON_CLEAR
});
