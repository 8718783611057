import {
  memo,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { noop } from '../../../utils/index';
import useBooleanState from '../../../hooks/useBooleanState';
import Heading from '../../UI/Heading';
import Button from '../../UI/Button';

import styles from './ConfirmCountry.module.scss';
import CountrySelectModal from '../../CountrySelectModal/index';
import useCountry from '../../../hooks/useCountry';

const ConfirmCountry = ({
  countryList,
  onConfirm,
  onChange,
  onClose
}) => {
  const { t } = useTranslation('layout', { keyPrefix: 'ConfirmCountry' });
  const { isOpen, close, open } = useBooleanState();
  const countryCode = useCountry();

  const currentCountryName = useMemo(() => {
    if (!countryCode || !countryList) {
      return 'Unknown';
    }

    const currentCountry = countryList.find((item) => item.value.toLowerCase() === countryCode);

    return currentCountry?.label || 'Unknown #2';
  }, [countryCode, countryList]);

  const onConfirmButtonClick = useCallback(() => {
    onConfirm(countryCode?.toLowerCase());
    onClose();
  }, [countryCode, onClose, onConfirm]);
  const onChangeButtonClick = useCallback(() => {
    open();
  }, [open]);
  const onCountryChange = useCallback((code) => {
    onChange(code?.toLowerCase());
    onClose();
  }, [onChange, onClose]);

  return (
    <div className={styles.root}>
      <Heading
        level="4"
        className={styles.title}
      >
        <Trans
          t={t}
          i18nKey="title"
        />
      </Heading>

      <div className={styles.text}>
        <Trans
          t={t}
          i18nKey="text"
        />
      </div>

      <div className={styles.bottom}>
        <div className={styles.country}>
          {!!countryCode && (
            <img
              alt={countryCode}
              crossOrigin="anonymous"
              width="28"
              height="20"
              src={`https://countryflagsapi.com/svg/${countryCode}/`}
              className={styles.flag}
            />
          )}

          {currentCountryName}
        </div>

        <div className={styles.controls}>
          <Button
            size="xs"
            className={styles.button}
            onClick={onConfirmButtonClick}
          >
            {t('confirmButtonText')}
          </Button>

          <Button
            size="xs"
            theme="light"
            className={styles.button}
            onClick={onChangeButtonClick}
          >
            {t('changeButtonText')}
          </Button>
        </div>
      </div>

      <CountrySelectModal
        open={isOpen}
        country={countryCode}
        onClose={close}
        onSelect={onCountryChange}
      />
    </div>
  );
};

ConfirmCountry.propTypes = {
  countryList: PropTypes.array,
  onConfirm: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func
};

ConfirmCountry.defaultProps = {
  countryList: [],
  onConfirm: noop,
  onChange: noop,
  onClose: noop
};

export default memo(ConfirmCountry);
