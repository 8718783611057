import { useCallback, useState } from 'react';

const useBooleanState = (initialValue = false) => {
  const [isOpen, setOpen] = useState(initialValue);
  const open = useCallback(() => {
    setOpen(true);
  }, []);
  const close = useCallback(() => {
    setOpen(false);
  }, []);
  const toggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return {
    isOpen,
    open,
    close,
    toggle
  };
};

export default useBooleanState;
