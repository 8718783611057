import {
  memo,
  useState,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { noop } from '../../../utils/index';
import useDownloadApp from '../../../hooks/useDownloadApp';
import Button from '../../UI/Button';
import Heading from '../../UI/Heading';
import Icon from '../../UI/Icon';
import Image from '../../UI/Image';
import LogoSymbol from '../../LogoSymbol';
import SendAppLinkViaEmailForm from '../../SendAppLinkViaEmailForm';

import envelopeImage from './Envelope.svg';
import styles from './NotWindowsDownloadDialog.module.scss';

const supportEmail = process.env.NEXT_PUBLIC_SUPPORT_EMAIL;

const NotWindowsDownloadDialog = ({
  data,
  onClose
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'Modals.NotWindowsDownloadDialog' });
  const [email, setEmail] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const onShowEmailForm = useCallback(() => {
    setShowEmailForm(true);
  }, []);
  const onEmailFormSuccess = useCallback((_, formData) => {
    setEmail(formData?.email || '');
    setShowSuccessMessage(true);
  }, []);
  const onDownloadApp = useDownloadApp(data?.sourceBlock);

  const handleClick = useCallback(() => {
    onDownloadApp();
    onClose();
  }, [onDownloadApp, onClose]);

  return (
    <div className={styles.root}>
      {showSuccessMessage && (
        <>
          <Image
            src={envelopeImage}
            width={154}
            height={143}
          />

          <Heading
            level="4"
            tag="h2"
            className={styles.successTitle}
          >
            <Trans
              t={t}
              i18nKey="successTitle"
              values={{ email }}
            />
          </Heading>

          <div className={styles.successText}>
            <Trans
              t={t}
              i18nKey="successText"
            >
              If there is no email, check the spam folder or email us:
              {' '}<a href={`mailto:${supportEmail}`}>{{ supportEmail }}</a>
            </Trans>
          </div>

          <Button
            className={styles.finishButton}
            onClick={onClose}
          >
            {t('finishButtonText')}
          </Button>
        </>
      )}

      {!showSuccessMessage && (
        <>
          <div className={styles.logoSymbol}>
            <LogoSymbol
              size={100}
            />

            <div className={styles.downloadIcon}>
              <Icon
                name="Download"
                size="18"
              />
            </div>
          </div>

          <Heading
            level="4"
            tag="h2"
            className={styles.heading}
          >
            <Trans
              t={t}
              i18nKey="title"
            />
          </Heading>

          <div className={styles.text}>
            <Trans
              t={t}
              i18nKey="text"
            >
              Note that the Upgrader only works on <span className={styles.highlighted}>Windows</span>
            </Trans>
          </div>

          <div className={styles.actions}>
            {showEmailForm && (
              <SendAppLinkViaEmailForm
                onSuccess={onEmailFormSuccess}
              />
            )}

            {!showEmailForm && (
              <Button
                stretch
                className={styles.emailButton}
                onClick={onShowEmailForm}
              >
                <Icon
                  name="Envelope"
                  size="20"
                  className={styles.emailButtonIcon}
                />

                {t('emailButtonText')}
              </Button>
            )}

            <button
              type="button"
              className={styles.downloadButton}
              onClick={handleClick}
            >
              {t('donwloadButtonText')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

NotWindowsDownloadDialog.propTypes = {
  data: PropTypes.shape({
    sourceBlock: PropTypes.string
  }),
  onClose: PropTypes.func
};

NotWindowsDownloadDialog.defaultProps = {
  data: null,
  onClose: noop
};

export default memo(NotWindowsDownloadDialog);
