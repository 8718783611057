import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useCallOnRouteChange = (callback) => {
  const router = useRouter();

  useEffect(() => {
    let mouted = true;

    const onRouteChange = (url) => {
      if (mouted) {
        callback(url);
      }
    };

    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      mouted = false;
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [callback, router.events]);
};

export default useCallOnRouteChange;
