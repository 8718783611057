module.exports = {
  // debug: true,
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'ru'],
    reloadOnPrerender: process.env.NODE_ENV === 'development',
    saveMissing: process.env.NODE_ENV === 'development',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'sup']
    }
  }
};
