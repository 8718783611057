import Cookies from 'js-cookie';

const createAuthMiddleware = (onUnauthorized) => () => (next) => async(action) => {
  if (!action) {
    return next(action);
  }

  const token = Cookies.get('access_token');

  if (!token) {
    return next(action);
  }

  const { request, status } = action;

  if (!request || !request.response || status !== 'FAIL') {
    return next(action);
  }

  const { statusCode } = request.response;

  if (statusCode === 401) {
    await onUnauthorized(action)(next);
  }

  return next(action);
};

export default createAuthMiddleware;
