import { createSelector } from 'reselect';

const slice = ({ app }) => app;

export const modalSelector = createSelector(
  slice,
  ({ modal }) => modal
);

export const modalDataSelector = createSelector(
  slice,
  ({ modalData }) => modalData || {}
);

export const confirmCountrySelector = createSelector(
  slice,
  ({ confirmCountry }) => confirmCountry
);

export const serverErrorSelector = createSelector(
  slice,
  ({ serverError }) => serverError
);

export const serverUnavailableSelector = createSelector(
  slice,
  ({ serverUnavailable }) => serverUnavailable
);

export const serverUnavailableDateSelector = createSelector(
  slice,
  ({ serverUnavailableDate }) => serverUnavailableDate
);
