import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  avatarSelector,
  nameSelector,
  hasSubscriptionSelector
} from '../../redux/profile/selectors';

import UserPreview from './UserPreview';

const mapStateToProps = createStructuredSelector({
  name: nameSelector,
  avatar: avatarSelector,
  hasSubscription: hasSubscriptionSelector
});

export default connect(mapStateToProps)(UserPreview);
