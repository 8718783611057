import {
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import equal from 'deep-equal';
import validate from 'validate.js';
import { useSelector } from 'react-redux';

import { noopPromise } from '../utils';
import { isSuccessAction } from '../redux/utils';
import usePrevious from './usePrevious';
import useMounted from './useMounted';
import { hasRecaptchaSelector } from '../redux/health/selectors';

const EMPTY_VALUES = [undefined, null, ''];

const isEqualValues = (value1, value2) => {
  if (EMPTY_VALUES.indexOf(value1) !== -1 && EMPTY_VALUES.indexOf(value2) !== -1) {
    return true;
  }

  return equal(value1, value2);
};

const useFormState = (
  initialState = {},
  constraints = {},
  onSubmit = noopPromise,
  onSuccess = noopPromise,
  onFail = noopPromise,
  withRecaptcha = false
) => {
  const mounted = useMounted();
  const hasRecaptcha = useSelector(hasRecaptchaSelector);
  const prevInitialState = usePrevious(initialState);
  const [formState, setFormState] = useState(initialState);
  const [processing, setProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const changed = useMemo(() => {
    const keys = Object.keys(formState);
    const changedFieldName = keys.find((key) => !isEqualValues(formState[key], initialState[key]));
    return !!changedFieldName;
  }, [formState, initialState]);

  const errors = useMemo(() => ({
    ...apiErrors,
    ...(validationErrors || {})
  }), [apiErrors, validationErrors]);

  const setField = useCallback((name, value) => {
    setFormState((state) => ({
      ...state,
      [name]: value
    }));
  }, []);

  const setForm = useCallback((data) => {
    setFormState((state) => ({
      ...state,
      ...data
    }));
  }, []);

  const onFieldChange = useCallback((value, name) => {
    if (errors && errors[name] && errors[name].length && constraints[name]) {
      const fieldData = { [name]: value };
      const fieldConstraints = { [name]: constraints[name] };
      const validation = validate(fieldData, fieldConstraints);

      setValidationErrors((prev) => ({
        ...(prev || {}),
        [name]: validation ? validation[name] : null
      }));
    }

    setFormState((state) => ({
      ...state,
      [name]: value
    }));
  }, [errors, constraints]);

  const onFormSubmit = useCallback(async(event) => {
    if (event) {
      event.preventDefault();
    }

    let recaptchaToken = null;

    if (withRecaptcha && hasRecaptcha) {
      if (!executeRecaptcha) {
        return;
      }

      recaptchaToken = await executeRecaptcha('yourAction');
    }

    if (processing) {
      return;
    }

    const sendData = Object.keys(formState).reduce((prev, key) => {
      let value = formState[key];

      if (typeof value === 'string') {
        value = value.trim();
      }

      if (value === undefined || value === '') {
        value = null;
      }

      return {
        ...prev,
        [key]: value
      };
    }, {});

    const validation = validate(sendData, constraints);
    setValidationErrors(validation);

    if (validation) {
      return;
    }

    setApiErrors(null);
    setProcessing(true);

    const action = await onSubmit(sendData, recaptchaToken, changed);

    if (!mounted.current) {
      return;
    }

    if (isSuccessAction(action)) {
      onSuccess(action, formState);
    } else {
      onFail(action);

      if (mounted.current) {
        setApiErrors(action?.request?.response?.data?.errors || null);
      }
    }

    if (!mounted.current) {
      return;
    }

    setProcessing(false);
  }, [
    changed,
    constraints,
    executeRecaptcha,
    formState,
    hasRecaptcha,
    mounted,
    onFail,
    onSubmit,
    onSuccess,
    processing,
    withRecaptcha
  ]);

  useEffect(() => {
    if (!equal(initialState, prevInitialState)) {
      setFormState(initialState);
    }
  }, [initialState, prevInitialState]);

  return {
    setField,
    setForm,
    onFormSubmit,
    onFieldChange,
    formState,
    changed,
    processing,
    errors
  };
};

export default useFormState;
