import { createSelector } from 'reselect';

const slice = ({ notifications }) => notifications;

export const notificationsSelector = createSelector(
  slice,
  ({ items }) => items || []
);
export const lastNotificationSelector = createSelector(
  notificationsSelector,
  (notifications) => notifications.pop() || null
);
