import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { authorizedSelector } from '../../redux/auth/selectors';
import { setModal } from '../../redux/app/actions';

import DownloadAppButton from './DownloadAppButton';

const mapStateToProps = createStructuredSelector({
  authorized: authorizedSelector
});

const mapDispatchToProps = {
  onNotWindowsClick: (sourceBlock) => (dispatch) => {
    dispatch(setModal('NotWindowsDownloadDialog', { sourceBlock }));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppButton);
