import { createSelector } from 'reselect';
import { defaultFetchingSelector } from '../utils';

const slice = ({ locations }) => locations;

export const fetchingSelector = defaultFetchingSelector(slice);
export const countriesSelector = createSelector(slice, ({ countries }) => countries || null);
export const currentSelector = createSelector(slice, ({ current }) => current || null);

export const countryOptionsSelector = createSelector(
  countriesSelector,
  (countries) => countries?.map(({ name, code }) => ({
    label: `${code} | ${name}`,
    name,
    value: code
  })) || []
);

export const currentCountryCodeSelector = createSelector(
  currentSelector,
  (current) => current?.country?.code?.toLowerCase() || null
);
export const currentCountryNameSelector = createSelector(
  currentSelector,
  (current) => current?.country?.name || null
);
