import { memo } from 'react';

import Link from '../../Link';
import Image from '../../UI/Image';
import Icon from '../../UI/Icon';

import logo from './logo.svg';
import styles from './LogoLink.module.scss';

const isHoliday = process.env.NEXT_PUBLIC_HOLIDAY_MODE === '1';

const LogoLink = () => (
  <Link
    href="/"
    className={styles.root}
  >
    {isHoliday && (
      <Icon
        size="10"
        name="ChristmasTree"
        className={styles.icon}
      />
    )}
    <Image
      src={logo}
      alt="UPGRADER"
      width="151"
      height="24"
    />
  </Link>
);

LogoLink.propTypes = {
};

LogoLink.defaultProps = {
};

export default memo(LogoLink);
