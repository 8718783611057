import qs from 'querystringify';
import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';
import Cookies from 'js-cookie';

import { getSavedLocale } from '../../utils';

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const wpApiBaseUrl = process.env.NEXT_PUBLIC_WP_API_BASE_URL;

if (!apiBaseUrl) {
  throw new Error('`NEXT_PUBLIC_API_BASE_URL` is undefined');
}

if (!wpApiBaseUrl) {
  throw new Error('`NEXT_PUBLIC_WP_API_BASE_URL` is undefined');
}

const buildApiUrl = (params) => {
  const {
    endpoint,
    queryParams
  } = params;

  const query = qs.stringify(queryParams, true);

  if (endpoint.includes('wp/v2')) {
    return `${wpApiBaseUrl}/${endpoint}${query}`;
  }

  return `${apiBaseUrl}/${endpoint}${query}`;
};

const buildOptions = (params) => {
  const {
    method = 'GET',
    contentType = 'json',
    headers = {},
    data
  } = params;

  const locale = params.locale || getSavedLocale();
  const options = {
    method,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Language': locale,
      'Accept-Language': locale,
      ...headers
    }
  };

  const token = Cookies.get('access_token');

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (data && contentType === 'json') {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(snakeCase(data, { deep: true }));
  }

  if (data && contentType === 'form-data') {
    const body = (!Array.isArray(data)) ? [data] : data;

    options.body = new FormData();
    body.forEach((el) => {
      if (el.filename) {
        options.body.append(el.name, el.value, el.filename);
      } else {
        options.body.append(el.name, el.value);
      }
    });
  }

  return options;
};

const makeRequest = async(url, options) => {
  const response = await fetch(url, options);

  const {
    status,
    ok,
    headers,
    statusText
  } = response;

  const result = {
    statusCode: status,
    statusText,
    headers
  };

  result.data = null;

  try {
    const json = await response.json();
    result.data = camelCase(json, { deep: true });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error while parsing JSON');
  }

  if (!ok) {
    throw result;
  }

  return result;
};

const actionWith = (action, status, payload) => ({
  ...action,
  status,
  ...payload
});

const createApiMiddleware = () => () => (next) => (action) => {
  if (!action) {
    return next(action);
  }

  const { request } = action;

  if (!request) {
    return next(action);
  }

  const {
    endpoint,
    queryParams,
    method,
    contentType,
    headers,
    locale,
    data
  } = request;

  const reqUrl = request.url || buildApiUrl({
    endpoint,
    queryParams
  });

  if (!reqUrl) {
    throw new Error('Can\'t build request url');
  }

  const reqOptions = buildOptions({
    method,
    contentType,
    headers,
    locale,
    data
  });

  if (!reqOptions) {
    throw new Error('Can\'t build request options');
  }

  next(actionWith(action, 'SEND'));

  return makeRequest(reqUrl, reqOptions)
    .then(
      (response) => next(actionWith(action, 'SUCCESS', {
        request: {
          ...request,
          response
        }
      })),
      (response) => next(actionWith(action, 'FAIL', {
        request: {
          ...request,
          response
        }
      }))
    );
};

export default createApiMiddleware;
