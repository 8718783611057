import { HYDRATE } from 'next-redux-wrapper';
import {
  createReducer,
  defaultFetchReducer
} from '../utils';
import {
  GET_RECAPTCHA_KEY
} from './constants';

const initialState = {
  fetching: false,
  recaptchaKey: null
};

const health = createReducer(initialState, {
  [HYDRATE]: (state, action) => ({
    ...state,
    recaptchaKey: state.recaptchaKey || action.payload.health.recaptchaKey
  }),

  [GET_RECAPTCHA_KEY]: (state, action) => defaultFetchReducer(state, action, {
    SUCCESS: () => ({
      ...state,
      fetching: false,
      recaptchaKey: action.request.response.data.siteKey || null
    })
  })
});

export default health;
