import ls from 'local-storage';

import { createActionRequestWithAuth, isSuccessAction } from '../utils';
import {
  GET_PROFILE,
  REQUEST_EMAIL_CHANGE,
  CONFIRM_EMAIL_CHANGE,
  CHANGE_PASSWORD,
  CHANGE_COUNTRY,
  RESET_STATE
} from './constants';
import { addSuccessNotification } from '../notifications/actions';
import { getPlansList } from '../plansList/actions';
import { fetchingSelector } from './selectors';

export const getProfile = () => (dispatch, getState) => {
  const fetching = fetchingSelector(getState());

  if (fetching) {
    return Promise.resolve();
  }

  return dispatch(createActionRequestWithAuth({
    type: GET_PROFILE,
    request: {
      endpoint: 'users/current'
    }
  }));
};

export const requestEmailChange = ({ email, password }) => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: REQUEST_EMAIL_CHANGE,
    request: {
      method: 'POST',
      endpoint: 'users/emails/change',
      data: {
        email,
        password
      }
    }
  }));

  if (isSuccessAction(action)) {
    const { message, lastVerificationMailSent } = action.request.response?.data || {};

    if (message) {
      dispatch(addSuccessNotification(action.request.response.data.message));
    }

    if (lastVerificationMailSent) {
      ls.set('last_verification_mail_sent', lastVerificationMailSent);
    }
  }

  return action;
};

export const confirmEmailChange = ({ email, code }) => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: CONFIRM_EMAIL_CHANGE,
    request: {
      method: 'PUT',
      endpoint: 'users/emails/change',
      data: {
        email,
        code
      }
    }
  }));

  if (isSuccessAction(action)) {
    dispatch(getProfile());
  }

  return action;
};

export const changePassword = (data) => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: CHANGE_PASSWORD,
    request: {
      method: 'PATCH',
      endpoint: 'users/password/change',
      data: {
        old: data.old,
        new: data.new
      }
    }
  }));

  if (isSuccessAction(action)) {
    dispatch(getProfile());
  }

  return action;
};

export const changeCountry = (code) => async(dispatch) => {
  const action = await dispatch(createActionRequestWithAuth({
    type: CHANGE_COUNTRY,
    request: {
      method: 'PUT',
      endpoint: 'users/locations/country',
      data: { code }
    }
  }));

  if (isSuccessAction(action)) {
    dispatch(getProfile());
    dispatch(getPlansList());
  }

  return action;
};

export const resetProfile = () => ({
  type: RESET_STATE
});
