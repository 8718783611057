import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { noop } from '../../utils';
import useDownloadApp from '../../hooks/useDownloadApp';
import useIsWindows from '../../hooks/useIsWindows';
// import useCountry from '../../hooks/useCountry';
import Button from '../UI/Button';
import ButtonLink from '../UI/ButtonLink';
import Icon from '../UI/Icon';

import styles from './DownloadAppButton.module.scss';

const ICON_SIZE_MAP = {
  lg: 21,
  xs: 16
};

const DownloadAppButton = ({
  authorized,
  theme,
  size,
  title,
  sourceBlock,
  forceButton,
  className,
  onNotWindowsClick,
  onClick,
  disabled
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'DownloadAppButton' });
  const onDownloadApp = useDownloadApp(sourceBlock);
  const isWindows = useIsWindows();
  // const country = useCountry();

  // const disabled = useMemo(() => (
  //   (!authorized || (!hasSubscription && country !== 'ru')) && !downloadOnly
  // ), [authorized, country, hasSubscription, downloadOnly]);
  //
  // const noSubscriptionHref = useMemo(() => (
  //   authorized ? '/profile/subscription/' : '/sign-in/'
  // ), [authorized]);
  //
  const resultClassName = cn(styles.root, styles[size], className);

  const onDownloadButtonClick = useCallback(() => {
    onClick();

    if (!isWindows) {
      onNotWindowsClick(sourceBlock);
      return;
    }

    onDownloadApp();
  }, [isWindows, onClick, onNotWindowsClick, sourceBlock, onDownloadApp]);

  const onLoginButtonClick = useCallback(() => {
    onClick();
  }, [onClick]);

  if (!authorized && !forceButton) {
    return (
      <ButtonLink
        href="/sign-in/?after=download"
        size={size}
        theme={theme}
        className={resultClassName}
        onClick={onLoginButtonClick}
      >
        <span className={styles.icon}>
          <Icon
            name="Windows"
            size={ICON_SIZE_MAP[size]}
          />
        </span>

        {title || t('noSubscriptionTitle')}
      </ButtonLink>
    );
  }

  return (
    <Button
      size={size}
      theme={theme}
      className={resultClassName}
      disabled={disabled}
      title={disabled ? t('tooltip') : null}
      onClick={onDownloadButtonClick}
    >
      <span className={styles.icon}>
        <Icon
          name="Windows"
          size={ICON_SIZE_MAP[size]}
        />
      </span>

      {title || t('title')}
    </Button>
  );
};

DownloadAppButton.propTypes = {
  authorized: PropTypes.bool,
  theme: PropTypes.oneOf(['primary', 'light', 'dark']),
  size: PropTypes.oneOf(['lg', 'md', 'xs']),
  title: PropTypes.string,
  sourceBlock: PropTypes.string,
  forceButton: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onNotWindowsClick: PropTypes.func,
  onClick: PropTypes.func
};

DownloadAppButton.defaultProps = {
  authorized: false,
  theme: 'primary',
  size: 'lg',
  title: null,
  sourceBlock: null,
  forceButton: false,
  disabled: false,
  className: null,
  onNotWindowsClick: noop,
  onClick: noop
};

export default memo(DownloadAppButton);
