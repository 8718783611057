import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Image from '../UI/Image';
import Icon from '../UI/Icon';

import styles from './UserPreview.module.scss';

import SantaHat from '../../images/santahat.png';

const isHoliday = process.env.NEXT_PUBLIC_HOLIDAY_MODE === '1';

const UserPreview = ({
  name,
  avatar,
  hasSubscription,
  size
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'UserPreview' });

  return (
    <div
      className={cn(styles.root, {
        [styles.hasSubscription]: hasSubscription,
        [styles[size]]: styles[size]
      })}
    >
      <div className={styles.avatarContainer}>
        {isHoliday && size === 'sm' && (
          <div className={styles.holidayIcon}>
            <Image
              src={SantaHat}
              objectFit="cover"
              layout="fill"
              size="40px"
            />
          </div>
        )}
        <div className={cn(styles.avatar, { [styles.holidayAvatar]: isHoliday })}>
          {!!avatar && (
            <div className={styles.image}>
              <Image
                src={avatar}
                alt="User pic"
                layout="fill"
                objectFit="cover"
                size="100px"
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.center}>
        <div className={styles.name}>
          {name}
        </div>

        <div className={styles.subscriptionLabel}>
          {hasSubscription && (
            <Icon
              name="Crown"
              size={size === 'md' ? 12 : 11}
              className={styles.subscriptionIcon}
            />
          )}

          {hasSubscription ? t('hasSubscriptionText') : t('noSubscriptionText')}
        </div>
      </div>
    </div>
  );
};

UserPreview.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  hasSubscription: PropTypes.bool,
  size: PropTypes.oneOf([
    'md',
    'sm'
  ])
};

UserPreview.defaultProps = {
  avatar: null,
  hasSubscription: false,
  size: 'md'
};

export default memo(UserPreview);
