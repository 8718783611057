import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { readySelector } from '../../../redux/profile/selectors';

import { logout } from '../../../redux/auth/actions';

import UserBar from './UserBar';

const mapStateToProps = createStructuredSelector({
  ready: readySelector
});

const mapDispatchToProps = {
  onLogout: logout
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
