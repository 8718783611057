const YANDEX_METRIKA_ID = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID;

export const ymSendData = (name, data, options) => {
  if (typeof window === 'undefined' || typeof window.ym !== 'function' || !YANDEX_METRIKA_ID) {
    return;
  }

  window.ym(YANDEX_METRIKA_ID, name, data, options);
};

export const ymSendPageView = (url) => ymSendData('hit', url);

export const ymSendPurchase = (item, invoice, coupon = null) => {
  if (typeof window === 'undefined' || !window.dataLayer || !invoice) {
    return;
  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    ecommerce: {
      currencyCode: invoice.price.currency.code,
      purchase: {
        actionField: {
          id: invoice.id,
          revenue: invoice.amount,
          coupon,
          goal_id: 250533623
        },
        products: [
          {
            id: item.uid,
            name: item.name,
            price: item.withTrial ? invoice.amount : item.amount,
            quantity: 1
          }
        ]
      }
    }
  });
};
