import Cookies from 'js-cookie';

import {
  SEND_APP_DOWNLOAD_EVENT,
  SEND_APP_LINK
} from './constants';

export const sendAppDownloadEvent = () => (dispatch) => {
  const token = Cookies.get('access_token');
  const queryParams = token ? { token } : null;

  dispatch({
    type: SEND_APP_DOWNLOAD_EVENT,
    request: {
      endpoint: 'software/download/installer',
      queryParams
    }
  });
};

export const sendAppLink = (email) => (dispatch) => dispatch({
  type: SEND_APP_LINK,
  request: {
    endpoint: 'software/link',
    queryParams: {
      email
    }
  }
});
