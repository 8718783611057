import Cookies from 'js-cookie';

const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

export const getClientId = async() => {
  let clientId = null;

  if (typeof window === 'undefined') {
    return clientId;
  }

  if (typeof window.gtag === 'function' && GOOGLE_ANALYTICS_ID) {
    clientId = await new Promise((resolve) => {
      window.gtag('get', GOOGLE_ANALYTICS_ID, 'client_id', resolve);
    });
  } else {
    clientId = await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!Cookies.get('_ga')) {
          return;
        }

        clearInterval(interval);
        resolve(Cookies.get('_ga'));
      });
    });
  }

  return clientId;
};

export const gaSendData = (data) => {
  if (typeof window === 'undefined' || typeof window.gtag !== 'function' || !GOOGLE_ANALYTICS_ID) {
    return;
  }

  window.gtag('config', GOOGLE_ANALYTICS_ID, data);
};

export const gaSendEvent = (action, payload) => {
  if (typeof window === 'undefined' || typeof window.gtag !== 'function') {
    return;
  }

  window.gtag('event', action, payload);
};

export const gaSendPageView = (path) => {
  if (typeof window === 'undefined' || typeof window.gtag !== 'function') {
    return;
  }

  window.gtag('set', 'page_path', path);
  window.gtag('event', 'page_view');
};

export const gaSendPurchase = (item, invoice, coupon = null) => {
  if (!item || !invoice) {
    return;
  }

  gaSendEvent('purchase', {
    coupon,
    currency: invoice.price.currency.code,
    items: [{
      item_id: item.uid,
      item_name: item.name,
      price: item.withTrial ? invoice.amount : item.amount,
      currency: invoice.price.currency.code,
      quantity: 1
    }],
    transaction_id: invoice.id,
    value: invoice.amount
  });
};
