import { connect } from 'react-redux';

import { createDispatchPropsFactory } from '../../../utils';
import { createPayment } from '../../../redux/checkout/actions';

import PaymentFailed from './PaymentFailed';

const mapDispatchToProps = createDispatchPropsFactory(
  (dispatch, { data }) => ({
    onRetry: () => {
      if (!data?.plan || !data?.provider) {
        return;
      }

      dispatch(createPayment(data.plan, data.provider));
    }
  }),
  ['data']
);

export default connect(null, mapDispatchToProps)(PaymentFailed);
