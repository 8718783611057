import {
  forwardRef,
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../../utils';

import styles from './InputText.module.scss';

const InputText = memo(forwardRef(({
  type,
  label,
  disabled,
  readOnly,
  error,
  className,
  onChange,
  onFocus,
  onBlur,
  ...props
}, ref) => {
  const [focus, setFocus] = useState(false);

  const onChangeProxy = useCallback((event) => {
    onChange(event.target.value, event.target.name, event);
  }, [onChange]);

  const onFocusProxy = useCallback((event) => {
    setFocus(true);
    onFocus(event);
  }, [onFocus]);

  const onBlurProxy = useCallback((event) => {
    setFocus(false);
    onBlur(event);
  }, [onBlur]);

  return (
    <label
      className={cn(styles.root, {
        [styles.disabled]: disabled,
        [styles.readOnly]: readOnly,
        [styles.focus]: focus,
        [styles.error]: error,
        [styles.hasLabel]: label && !disabled
      }, className)}
    >
      {(!!label && !disabled) && (
        <div className={styles.label}>
          {label}
        </div>
      )}

      <input
        ref={ref}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        className={styles.input}
        onChange={onChangeProxy}
        onFocus={onFocusProxy}
        onBlur={onBlurProxy}
        {...props}
      />
    </label>
  );
}));

InputText.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'url',
    'password'
  ]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

InputText.defaultProps = {
  type: 'text',
  label: null,
  disabled: false,
  readOnly: false,
  error: false,
  className: null,
  onChange: noop,
  onFocus: noop,
  onBlur: noop
};

export default InputText;
