import { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from '../Link';
import LogoSymbol from '../LogoSymbol';

import styles from './LogoSymbolLink.module.scss';

const LogoSymbolLink = ({
  href,
  size,
  className
}) => (
  <Link
    href={href}
    className={cn(styles.root, className)}
  >
    <LogoSymbol size={size} />
  </Link>
);

LogoSymbolLink.propTypes = {
  href: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
};

LogoSymbolLink.defaultProps = {
  href: '/',
  size: 100,
  className: null
};

export default memo(LogoSymbolLink);
