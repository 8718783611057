import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../../UI/ButtonLink';
import DownloadAppButton from '../../DownloadAppButton';

import styles from './Controls.module.scss';

const Controls = ({ authorized, hasSubscription }) => {
  const { t } = useTranslation('layout', { keyPrefix: 'Controls' });

  return (
    <div className={styles.root}>
      {!authorized && (
        <ButtonLink
          href="/sign-in/?after=profile"
          size="xs"
          className={styles.link}
        >
          {t('signInButtonText')}
        </ButtonLink>
      )}

      {authorized && (
        <DownloadAppButton
          size="xs"
          disabled={!hasSubscription}
          className={styles.download}
          title={t('downloadButtonText')}
          sourceBlock="Header"
        />
      )}
    </div>
  );
};

Controls.propTypes = {
  authorized: PropTypes.bool,
  hasSubscription: PropTypes.bool
};

Controls.defaultProps = {
  authorized: false,
  hasSubscription: false
};

export default memo(Controls);
