import validate from 'validate.js';

const configureValidation = (t) => {
  validate.options = {
    fullMessages: false
  };

  validate.validators.presence.options = {
    allowEmpty: false,
    message: t('requiredFieldError', { ns: 'validation' })
  };

  validate.validators.email.options = {
    message: t('emailFieldError', { ns: 'validation' })
  };

  validate.validators.length.options = {
    tooShort: t('lengthTooShortFieldError', { ns: 'validation' }),
    tooLong: t('lengthTooLongFieldError', { ns: 'validation' })
  };
};

export default configureValidation;
