import { createReducer } from '../utils';
import { SET_PROCESSING } from './constants';

const initialState = {
  processing: false
};

const checkout = createReducer(initialState, {
  [SET_PROCESSING]: (state, { processing }) => ({
    ...state,
    processing
  })
});

export default checkout;
