import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { createArray } from '../../../utils';

import styles from './Spinner.module.scss';

const Spinner = ({
  size,
  thickness,
  theme,
  duration
}) => {
  const rootStyle = useMemo(() => ({
    width: size,
    height: size
  }), [size]);

  const radians = useMemo(() => createArray(4, (_, index) => ({
    key: index,
    style: {
      borderWidth: Math.min(thickness, size / 2),
      animationDuration: `${duration}ms`,
      animationDelay: `${(0.375 * duration) - (index * 0.125 * duration)}ms`
    }
  })), [size, thickness, duration]);

  return (
    <div
      className={cn(styles.root, styles[theme])}
      style={rootStyle}
    >
      {radians.map((radian) => (
        <div
          key={radian.key}
          className={styles.radian}
          style={radian.style}
        />
      ))}
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  theme: PropTypes.oneOf([
    'primary',
    'white'
  ]),
  duration: PropTypes.number
};

Spinner.defaultProps = {
  size: 32,
  thickness: 2,
  theme: 'primary',
  duration: 1000
};

export default memo(Spinner);
