import { connect } from 'react-redux';

import { notificationsSelector } from '../../redux/notifications/selectors';
import { removeNotification } from '../../redux/notifications/actions';

import Notifications from './Notifications';

const mapStateToProps = (state) => ({
  list: notificationsSelector(state)
});

const mapDispatchToProps = {
  onClose: removeNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
