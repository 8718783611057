import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { noop } from '../../../utils';
import Heading from '../../UI/Heading';
import Button from '../../UI/Button';

import styles from './PaymentFailed.module.scss';

const PaymentFailed = ({
  data,
  onRetry,
  onClose
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'Modals.PaymentFailed' });

  const onButtonClick = useCallback(() => {
    onRetry();
    onClose();
  }, [onClose, onRetry]);

  return (
    <div className={styles.root}>
      <Heading
        level="4"
        className={styles.heading}
      >
        <Trans
          t={t}
          i18nKey="title"
        />
      </Heading>

      <div className={styles.text}>
        <Trans
          t={t}
          i18nKey="text"
        />
      </div>

      {!!data?.plan && !!data?.provider && (
        <Button
          type="button"
          className={styles.button}
          onClick={onButtonClick}
        >
          {t('buttonText')}
        </Button>
      )}
    </div>
  );
};

PaymentFailed.propTypes = {
  data: PropTypes.shape({
    plan: PropTypes.object,
    provider: PropTypes.string
  }),
  onRetry: PropTypes.func,
  onClose: PropTypes.func
};

PaymentFailed.defaultProps = {
  data: null,
  onRetry: noop,
  onClose: noop
};

export default memo(PaymentFailed);
