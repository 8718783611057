import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Button.module.scss';

const Button = memo(forwardRef(({
  type,
  theme,
  size,
  stretch,
  children,
  className,
  ...props
}, ref) => (
  <button
    ref={ref}
    type={type} // eslint-disable-line react/button-has-type
    className={cn({
      [styles[theme]]: styles[theme],
      [styles[size]]: styles[size],
      [styles.stretch]: stretch
    }, className)}
    {...props}
  >
    {children}
  </button>
)));

Button.propTypes = {
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset'
  ]),
  theme: PropTypes.oneOf([
    'primary',
    'light',
    'dark'
  ]),
  size: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg'
  ]),
  stretch: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

Button.defaultProps = {
  type: 'button',
  theme: 'primary',
  size: 'md',
  stretch: false,
  className: null,
  children: null
};

export default Button;
