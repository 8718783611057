const createApiErrorsMiddleware = (onError) => () => (next) => (action) => {
  if (!action || typeof onError !== 'function') {
    return next(action);
  }

  const { request, status } = action;

  if (request && status === 'FAIL' && request.response) {
    onError(action)(next);
  }

  return next(action);
};

export default createApiErrorsMiddleware;
