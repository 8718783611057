import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  countryOptionsSelector,
  countriesSelector
} from '../../../redux/locations/selectors';

import CountrySwitch from './CountrySwitch';

const readySelector = createSelector(
  countriesSelector,
  (countries) => !!countries
);

const mapStateToProps = createStructuredSelector({
  ready: readySelector,
  countryList: countryOptionsSelector
});

export default connect(mapStateToProps)(CountrySwitch);
