import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { downloadUrl } from '../utils';
import { ymSendData } from '../utils/ym';
import { gaSendEvent } from '../utils/ga';
import { sendAppDownloadEvent } from '../redux/software/actions';
import { redirect } from '../i18n/redirect';

const useDownloadApp = (sourceBlock) => {
  const dispatch = useDispatch();

  const onDownload = useCallback(() => {
    dispatch(sendAppDownloadEvent());
    ymSendData('reachGoal', 'download');
    gaSendEvent('download_file');
    if (sourceBlock) {
      gaSendEvent('download', { source_block: sourceBlock });
    } else {
      gaSendEvent('download');
    }

    downloadUrl(process.env.NEXT_PUBLIC_APP_DOWNLOAD_LINK);

    redirect('/download/thank-you/');
  }, [dispatch, sourceBlock]);

  return onDownload;
};

export default useDownloadApp;
