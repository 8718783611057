import { HYDRATE } from 'next-redux-wrapper';
import {
  createReducer,
  defaultFetchDataState,
  defaultFetchReducer
} from '../utils';
import { GET_ARTICLE } from './constants';

const article = createReducer(defaultFetchDataState, {
  [GET_ARTICLE]: defaultFetchReducer,

  [HYDRATE]: (state, action) => ({
    ...state,
    ...action.payload.article
  })
});

export default article;
