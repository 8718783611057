import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { noopPromise, noop } from '../../utils';
import useFormState from '../../hooks/useFormState';
import Button from '../UI/Button';
import Field from '../UI/Field';
import Form from '../UI/Form';
import Icon from '../UI/Icon';
import InputText from '../UI/InputText';

import styles from './SendAppLinkViaEmailForm.module.scss';

const CONSTRAINTS = {
  email: {
    presence: true,
    email: true
  }
};

const SendAppLinkViaEmailForm = ({
  email,
  onSubmit,
  onSuccess,
  onFail
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'SendAppLinkViaEmailForm' });
  const initialData = useMemo(() => ({
    email: email || ''
  }), [email]);
  const {
    onFormSubmit,
    onFieldChange,
    formState,
    errors,
    processing
  } = useFormState(initialData, CONSTRAINTS, onSubmit, onSuccess, onFail);

  return (
    <Form
      processing={processing}
      className={styles.form}
      onSubmit={onFormSubmit}
    >
      <Field error={errors.email}>
        <InputText
          autoFocus
          type="email"
          name="email"
          label={t('emailFieldLabel')}
          placeholder={t('emailFieldPlaceholder')}
          value={formState.email}
          error={Boolean(errors?.email)}
          onChange={onFieldChange}
        />
      </Field>

      <Button
        type="submit"
        stretch
        disabled={processing}
        className={styles.submitButton}
      >
        <Icon
          name="Envelope"
          size="20"
          className={styles.icon}
        />

        {t('submitButtonText')}
      </Button>
    </Form>
  );
};

SendAppLinkViaEmailForm.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

SendAppLinkViaEmailForm.defaultProps = {
  email: null,
  onSubmit: noopPromise,
  onSuccess: noop,
  onFail: noop
};

export default memo(SendAppLinkViaEmailForm);
