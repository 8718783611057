import ls from 'local-storage';
import equal from 'deep-is';
import { createActionRequestWithAuth } from '../utils';
import { POST_COLLECT } from './constants';
import { getClientId } from '../../utils/ga';
import { collectUtmTags } from '../../utils';

// eslint-disable-next-line import/prefer-default-export
export const collectAnalytics = (clientId, domain, utm = null) => async(dispatch) => {
  const data = {
    clientId,
    domain
  };

  if (utm) {
    data.utm = utm;
  }

  dispatch(createActionRequestWithAuth({
    type: POST_COLLECT,
    request: {
      method: 'POST',
      endpoint: 'scitylana/tcelloc',
      data
    }
  }));
};

export const sendCollectedData = (query = null) => async(dispatch) => {
  const googleClientId = await getClientId();
  const domain = typeof window !== 'undefined' ? window.location.host : 'unknown';

  const clientId = googleClientId?.match(/\d+\.\d+$/)[0];
  const lsClientId = ls.get('clientId') || undefined;

  const queryUtm = collectUtmTags(query);
  const lsUtm = ls.get('utm') || undefined;

  if (lsClientId === clientId) {
    if (queryUtm && equal(queryUtm, lsUtm)) {
      return;
    }
  }

  dispatch(collectAnalytics(clientId, domain, queryUtm || lsUtm))
    .then(() => {
      ls.set('clientId', clientId);
      if (queryUtm) {
        ls.set('utm', queryUtm);
      }
    });
};
