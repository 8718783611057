import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Copyright.module.scss';

const Copyright = () => {
  const { t } = useTranslation('layout', { keyPrefix: 'Copyright' });

  return (
    <div className={styles.root}>
      {t('text')}
    </div>
  );
};

export default memo(Copyright);
