import {
  memo,
  useState,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useLocaleBasedEnv from '../../../hooks/useLocaleBasedEnv';
import useModal from '../../../hooks/useModal';
import BadgesList from '../../BadgesList';
import Heading from '../../UI/Heading';
import Hidden from '../../UI/Hidden';
import Icon from '../../UI/Icon';
import Link from '../../Link';
import LogoSymbolLink from '../../LogoSymbolLink';
import SocialLinks from '../../SocialLinks';
import TransitionExpand from '../../UI/TransitionExpand';
import Visible from '../../UI/Visible';

import styles from './FullNav.module.scss';

const useNav = (hasSubscription) => {
  const { t } = useTranslation('layout', { keyPrefix: 'Nav' });
  const { POLICY_URL, TERMS_URL } = useLocaleBasedEnv();
  const { open: openHowCancelSubscriptionModal } = useModal('HowCancelSubscription');
  const nav = useMemo(() => [
    {
      name: 'upgrader',
      title: t('upgrader'),
      items: [
        {
          name: 'pricing',
          type: 'link',
          href: hasSubscription ? '/profile/subscription/' : '/pricing/',
          title: t('pricing')
        },
        {
          name: 'home',
          type: 'link',
          href: '/',
          blank: false,
          title: t('home')
        },
        {
          name: 'games',
          type: 'link',
          href: '/games/',
          blank: false,
          title: t('games')
        }
        // {
        //   name: 'system',
        //   type: 'link',
        //   href: '/system/',
        //   blank: false,
        //   title: t('system')
        // }
      ]
    },
    {
      name: 'support',
      title: t('support'),
      items: [
        {
          name: 'contacts',
          type: 'link',
          href: '/contacts/',
          blank: false,
          title: t('contacts')
        },

        {
          name: 'policy-and-data',
          type: 'link',
          href: POLICY_URL,
          blank: true,
          title: t('policyAndData')
        },
        {
          name: 'agreement',
          type: 'link',
          href: TERMS_URL,
          blank: true,
          title: t('agreement')
        },
        {
          name: 'cancelSubscription',
          type: 'button',
          onClick: openHowCancelSubscriptionModal,
          title: t('cancelSubscription')
        }
      ]
    },
    // {
    //   name: 'about',
    //   title: t('about'),
    //   items: [
    //     {
    //       name: 'blog',
    //       type: 'link',
    //       href: '/blog/',
    //       blank: false,
    //       title: t('blog')
    //     },
    //     {
    //       name: 'jobs',
    //       type: 'link',
    //       href: '/jobs/',
    //       blank: false,
    //       title: t('jobs')
    //     }
    //   ]
    // },
    {
      name: 'followUs',
      title: t('followUs'),
      items: [
        {
          name: 'socialLinks',
          type: 'socialLinks'
        }
      ]
    }
  ], [POLICY_URL, TERMS_URL, hasSubscription, openHowCancelSubscriptionModal, t]);

  return nav;
};
const ItemsPropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['link', 'button', 'socialLinks']).isRequired,
  title: PropTypes.string,
  href: PropTypes.string,
  blank: PropTypes.bool,
  onClick: PropTypes.func
}));
const NavPropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: ItemsPropType
}));

const ItemsList = memo(({ items }) => (
  <>
    {items.map((item) => (
      <div
        key={item.name}
        className={styles.item}
      >
        {item.type === 'link' && (
          <Link
            href={item.href}
            simpleStyle
            target={item.blank ? '_blank' : null}
            className={styles.link}
          >
            {item.title}
          </Link>
        )}

        {item.type === 'button' && (
          <button
            type="button"
            className={styles.button}
            onClick={item.onClick}
          >
            {item.title}
          </button>
        )}

        {item.type === 'socialLinks' && (
          <SocialLinks />
        )}
      </div>
    ))}
  </>
));

ItemsList.propTypes = {
  items: ItemsPropType.isRequired
};

const AccordionItem = memo(({
  name,
  open,
  title,
  items,
  onClick
}) => {
  const onClickProxy = useCallback(() => {
    onClick(name);
  }, [name, onClick]);

  return (
    <>
      <button
        type="button"
        className={cn(styles.accordionButton, {
          [styles.accordionButtonActive]: open
        })}
        onClick={onClickProxy}
      >
        {title}

        <Icon
          name="Chevron"
          size="14"
          className={styles.chevron}
        />
      </button>

      <TransitionExpand
        open={open}
        duration={300}
      >
        <div className={styles.accordionBody}>
          <ItemsList items={items} />
        </div>
      </TransitionExpand>
    </>
  );
});

AccordionItem.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  items: ItemsPropType.isRequired,
  onClick: PropTypes.func.isRequired
};

const AccordionNav = memo(({ nav }) => {
  const [active, setActive] = useState(nav[0].name);
  const onClick = useCallback((name) => {
    setActive((prev) => (prev === name ? null : name));
  }, []);

  return (
    <div className={styles.accordion}>
      {nav.map((group) => (
        <AccordionItem
          key={group.name}
          name={group.name}
          open={active === group.name}
          title={group.title}
          items={group.items}
          onClick={onClick}
        />
      ))}
    </div>
  );
});

AccordionNav.propTypes = {
  nav: NavPropType.isRequired
};

const Nav = memo(({ nav }) => (
  <div className={styles.nav}>
    {nav.map((group) => (
      <div
        key={group.name}
        className={styles.col}
      >
        <Heading
          level="6"
          tag="h3"
          weight="500"
          className={styles.groupHeading}
        >
          {group.title}
        </Heading>

        <ItemsList items={group.items} />
      </div>
    ))}
  </div>
));

Nav.propTypes = {
  nav: NavPropType.isRequired
};

const FullNav = ({ hasSubscription }) => {
  const nav = useNav(hasSubscription);

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <LogoSymbolLink size={56} />

        <div className={styles.sourceForgeBadges}>
          <BadgesList
            sourceForge
            slashdot
          />
        </div>
      </div>

      <div className={styles.navWrapper}>
        <Hidden
          xs
          sm
        >
          <Nav nav={nav} />
        </Hidden>

        <Visible
          xs
          sm
        >
          <AccordionNav nav={nav} />
        </Visible>
      </div>
    </div>
  );
};

FullNav.propTypes = {
  hasSubscription: PropTypes.bool
};

FullNav.defaultProps = {
  hasSubscription: false
};

export default memo(FullNav);
