import {
  memo,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import cn from 'classnames';

import i18nextConfig from '../../../../next-i18next.config';
import LangLink from '../LangLink';
import Icon from '../../UI/Icon';
import Menu from '../../UI/Menu';

import styles from './LangSwitch.module.scss';

const DICT = {
  en: 'English',
  ru: 'Русский'
};

const LangSwitch = ({ short }) => {
  const { query } = useRouter();
  const current = useMemo(() => query.locale || i18nextConfig.i18n.defaultLocale, [query.locale]);
  const icon = useMemo(() => (short ? 'Globe' : 'Lang'), [short]);
  const currentLabel = useMemo(() => (short ? current : DICT[current]), [current, short]);

  return (
    <Menu
      handle={(
        <>
          <Icon
            name={icon}
            size="20"
            className={[styles.icon, short ? styles.iconGlobe : null ]}
          />

          {currentLabel}
        </>
      )}
      menu={(
        <>
          {i18nextConfig.i18n.locales.map((locale) => (
            <LangLink
              key={locale}
              locale={locale}
              title={DICT[locale]}
              active={locale === current}
            />
          ))}
        </>
      )}
      handleClassName={cn(styles.toggle, {
        [styles.short]: short
      })}
    />
  );
};

LangSwitch.propTypes = {
  short: PropTypes.bool
};

LangSwitch.defaultProps = {
  short: false
};

export default memo(LangSwitch);
