import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import languageDetector from './languageDetector';

export const redirect = (to, router) => {
  const r = router || Router;
  const resultTo = to || r.asPath;
  const detectedLng = languageDetector.detect();

  if (resultTo.startsWith(`/${detectedLng}`) && r.route === '/404') {
    r.replace(`/${detectedLng}${r.route}`);
  } else {
    languageDetector.cache(detectedLng);
    r.replace(`/${detectedLng}${resultTo}`);
  }
};

export const useRedirect = (to) => {
  const router = useRouter();

  useEffect(() => {
    redirect(to, router);
  });

  return null;
};

export const Redirect = ({ to }) => {
  useRedirect(to);
  return null;
};

export const getRedirect = (to) => () => {
  useRedirect(to);
  return null;
};
