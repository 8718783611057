import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './IframeCheckout.module.scss';

const IframeCheckout = ({ data }) => {
  useEffect(() => () => {
    const bc = new BroadcastChannel('iframe_checkout');
    bc.postMessage(JSON.stringify({ status: 'FAIL', reason: 'canceled' }));
  }, []);

  if (!data?.url) {
    return 'Error';
  }

  return (
    <div className={styles.root}>
      <iframe
        title="Checkout"
        src={data.url}
        style={{
          width: '100%',
          height: 725
        }}
        frameBorder="0"
      />
    </div>
  );
};

IframeCheckout.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string
  })
};

IframeCheckout.defaultProps = {
  data: null
};

export default memo(IframeCheckout);
